/* Authentication forms */

body,
div#wrapper {
    &:after, &:before {
        background-color: transparent;
    }
}
.auth-main{

    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 400px;
        height: 100%;
        z-index: -1;
        background: #191f28;

        @include max-screen($break-xsmall) {
            width: 100%;
        }
    }
    &:after{
        content: '';
        position: absolute;
        right:0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        background: url(../../../images/auth_bg2.jpg) no-repeat center center fixed;
        background-size: cover;
    }
    .card{
        padding:10px;
    }
}


.auth-box {
	width: 380px;
	height: auto;
    margin-left: 130px;

	.top{
        @extend .m-b-30;
        img{
            width: 90px;
        }
    }

	.form-auth-small {
		.btn{
            @extend .m-t-20;
		}

		.element-left {
			float: left;
		}

		.element-right {
			float: right;
		}

		.bottom {
            @extend .m-t-20;
			@extend .align-center;
		}
    }

    .lead {
        @extend .m-t-5;
        @extend .m-b-0;
        font-size: 14px;
        font-weight: 600;
    }

	.helper-text {
        color: $color-muted;
        @extend .displayblock;
	}

	.btn-signin-social {
        @extend .displayblock;
        @extend .m-b-5;
		padding: 10px 22px;
		width: 100%;
		background-color: $col-white;
		border-color: $grey-200;

		&:hover,
		&:focus {
			background-color: $grey-200;
		}

		i {
			font-size: 16px;
			margin: 5px;
		}
	}

	@include max-screen($break-medium) {
        width: 80%;
        margin: 0 auto;
	}

	@include max-screen($break-xsmall) {
        width: 90%;
	}
}
.input-group{
    input{
        border-right: 0;
        border-top-left-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius:var(--bs-border-radius) !important;
    }
    .input-group-text{
        background-color: transparent;
        font-size: 12px;
        font-weight: 300;
    }
}
select.form-control{
    background: url("data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 7px 7px;
    background-origin: content-box;
}
// checkbox css
.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        box-sizing: border-box;
        padding: 0;
    }
    .control__indicator {
        position: absolute;
        top: 0px;
        left: 0;
        height: 20px;
        width: 20px;
        background: transparent;
        border: 1px solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);

    }
    input:checked ~ .control__indicator {
        background: #49c5b6;
    }
    .control__indicator:after {
        left: 7px;
        top: 4px;
        width: 4px;
        height: 9px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        content: '';
        position: absolute;
        display: none;
    }
    input:checked ~ .control__indicator:after {
        display: block;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root,
html[data-theme='light'] {
  --primary-color: #0596ff;
  --text-color: #000000;
  --bg-color: #f4faff;
  --light-text-color: #acacac;
  --placeholder-text-color: #acacac;
  --dark-blue-color: #5f6377;
  --black-color-700: #4f4f4f;
  --green-color: #009143;
  --green-color-300: #009e48;
  --red-color: #ff3951;
  --bg-color-input: #e4f3ff;
  --secondary-text-color: #838383;
  --gray-color-200: #ededed;
  --gray-color-700: #606060;
  --th-color: #333333;
  --gray-color-300: #757575;
  --gray-color-200: #f7f7f7;
  --background-gradient: linear-gradient(
    314.4deg,
    #00fbff 17.43%,
    #00ff85 91.89%
  );
  --white-color: #fff;
  --fonts: 'Poppins', sans-serif;
  --breadcrum-border-color: #c6c6c6;
  --filter-bg: #696d80;
  --black-color: #000;
  --sidebar-active-color: #0596ff;
  --card-box-shadow: 0px 10px 60px 0px #e4f4ff;
}
html[data-theme='dark'] {
  --gray-color-200: #333333;
  --sidebar-active-color: #000;
  --bg-color-input: #ffffff;
  --card-box-shadow: 0px 10px 60px 0px #0000004d;
  --th-color: #fff;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  font-family: var(--fonts) !important;
  background-color: var(--bg-color) !important;
  scroll-behavior: smooth;
  font-size: 14px;
}
/* CSS for cdr-column-filter-icon starts here */
.sticky-filter-icon-container {
  position: relative;
}
.sticky-filter-icon-container .sticky-filter-icon {
  position: sticky;
  right: 1.23rem;
  z-index: 9;
}
.detail-col-filter-icon {
  width: 50px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: max-content !important;
}
/* CSS for cdr-column-filter-icon ends here */
/* CSS for multi-select dropdown starts here */
.form-data
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  margin-top: 12px !important;
}
/* classes for selected options and searchable input container */
.select2-selection,
.select2-selection--multiple {
  display: flex !important;
}
/* class for selected choice */
.select2-selection__choice {
  background-color: #5f6377 !important;
  border: none !important;
  border-radius: 5px !important;
  height: 32px;
}
/* class for clear choice button */
.select2-selection__choice__remove {
  border-right: none !important;
  font-size: 1.5em !important;
  font-weight: 200 !important;
  padding: 2px 0 0 0 !important;
  left: 8px !important;
  top: 50% !important;
  transform: translate(0, -50%) !important;
  color: #fff !important;
}
.select2-selection__choice__remove:hover {
  color: #fff !important;
  background-color: #5f6377 !important;
}
/* class for choice-text */
.select2-selection__choice__display {
  padding-left: 5px !important;
  line-height: 32px !important;
  color: #fff;
  font-size: 14px !important;
}
/* multi-select style for dark mode */
.full-dark .select2-selection__choice {
  background-color: #fff !important;
}
.full-dark .select2-selection__choice__remove,
.full-dark .select2-selection__choice__remove:hover {
  background-color: #fff !important;
  color: #000 !important;
}
/* CSS for multi-select dropdown ends here */
.auth-box .top img {
  width: 160px !important;
  margin-left: 0 !important;
  height: auto !important;
  margin-bottom: 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* toggle switch css :: BEGIN */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
  margin-bottom: 0;
  border-radius: 34px;
  margin-left: 10px;
}
.switch-with-inner-text {
  width: 85px;
  height: 25px;
  margin-left: 0;
}
.switch-with-inner-text .switch-inner-text {
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 0;
  bottom: 0;
  font-size: 13px;
  transform: translateX(26px);
  color: #fff;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #EDEDED; */
  background-color: var(--red-color);
  -webkit-transition: 0.4s;
  margin-left: 0 !important;
  transition: 0.4s;
}
.dropdown-toggle::after {
  content: '\f107' !important;
  font-family: FontAwesome;
  border: 0 !important;
}
.show.dropdown .dropdown-toggle::after {
  transform: rotate(180deg);
}
.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0px;
  top: 1px;
  bottom: 0;
  margin: auto 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: #5F6377; */
  background-color: #fff;
  box-shadow: 0px 0px 3px #00000082;
}

.switch-with-inner-text .slider:before {
  height: 20px;
  width: 20px;
  left: 2px;
  top: 0px;
  bottom: 0;
  margin: auto 0;
}

.cardData-section .card .card-body p {
  font-weight: 600;
  text-transform: uppercase;
  color: #444;
}

a {
  display: flex !important;
  align-items: center;
}

.light-text {
  color: var(--light-text-color);
  font-weight: 300;
}

input:checked + .slider {
  /* background-color: var(--primary-color); */
  background-color: var(--green-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--white-color);
}
.switch-with-inner-text input:checked + .slider:before {
  -webkit-transform: translateX(61px);
  -ms-transform: translateX(61px);
  transform: translateX(61px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* toggle switch css :: END */
.react-dropdown-select {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius) !important;
  min-height: 37px !important;
  height: auto !important;
}

.hide-border:before,
.hide-border:after {
  display: none;
}
.dd-item::marker {
  display: none !important;
  font-size: 0px;
}
.btn-outline-secondary:hover {
  color: #ffffff !important;
}
.modal.show .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
}
.navbar-fixed-top .navbar-brand img {
  margin-top: 0 !important;
}
:focus {
  outline: none !important;
  box-shadow: none !important;
}
.rsm-svg {
  height: 100%;
  width: 100%;
}

.modal {
  z-index: 11112 !important;
}

.image-gallery-play-button {
  display: none;
}

.image-gallery {
  width: 50%;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px !important;
  width: 60px !important;
}
.theme-cyan:before,
.theme-cyan:after {
  background: transparent !important;
}

.card .header .header-dropdown li .dropdown-menu,
.header-dropdown .dropdown-menu {
  background: #17191c;
  border-radius: 0.55rem;
  overflow: hidden;
  transform: none !important;
  left: auto !important;
  right: 10px !important;
  padding: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  border: 0;
  top: 10px !important;
  color: #999 !important;
}
.card .header .header-dropdown li a {
  color: #999 !important;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
}

.card .header .header-dropdown li a:hover {
  color: #fff !important;
}
.accordion .card .card-header {
  background-color: transparent !important;
}
.accordion .card .card-header .btn-link {
  color: #666;
}

.accordion .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}

.button-container {
  display: none !important;
}

.hide-toggle:after {
  display: none !important;
  margin-left: 0em !important;
  vertical-align: 0em !important;
  content: '' !important;
  border-top: 0em solid !important;
  border-right: 0em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0em solid transparent !important;
}

.form-control {
  height: 45px !important;
  background-color: var(--bg-color-input) !important;
  border-color: var(--bg-color-input) !important;
  color: #acacac !important;
}

.hide-toggle .btn-outline-secondary {
  height: calc(1.5em + 0.75rem + 5px) !important;
}
.jvectormap-container {
  height: 300px;
}
.jvectormap-zoomin {
  position: absolute;
  top: 0px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}
.jvectormap-zoomout {
  position: absolute;
  top: 27px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}
.list-group-item + .list-group-item {
  border-top-width: 1px !important;
}
.hclass {
  left: -40px !important;
}
/* registartion page css :: BEGIN */
body:after,
body:before,
div#wrapper:after,
div#wrapper:before {
  background-color: transparent !important;
  display: none;
}
/* registartion page css :: END */

/* Kyc Modal popup :: BEGIN */
.upload-section {
  position: relative;
}
.upload-section .d-flex {
  position: relative;
}
.upload-section .input-group-text {
  margin-left: -2px;
  border-radius: 5px;
  z-index: 2;
}
.upload-section .icon-close {
  margin-left: 10px;
}
.upload-section .upload-kyc.form-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
}
.input-group .input-group-text.btn-primary {
  background-color: #157ffb;
  line-height: 2;
  border-radius: 5px;
}
.upload-section i {
  background-color: #f1f1f1;
  height: 37px;
  line-height: 1.6;
  color: red;
  border: 0;
}
.text-red {
  color: red;
}
.gray-text {
  color: #6d757d;
}
.small-text {
  font-size: 11px;
}
.modal-content {
  padding: 15px;
  box-shadow: 0 12px 4px #00000042;
  border: 0 !important;
}
.modal-footer {
  justify-content: flex-start !important;
  border-top: 0 !important;
  padding-top: 0 !important;
}
.modal-header {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.modal-header .modal-title {
  color: var(--dark-blue-color);
  font-size: 20px;
  font-weight: 500;
}
.modal-body p {
  margin-bottom: 1.5rem !important;
  color: var(--text-color);
  /* word-break: break-all; */
  word-break: break-word;
}
.word-break {
  word-break: break-all;
}
.primary-btn {
  background-color: var(--primary-color);
}
.modal-backdrop {
  background-color: #dbdbdb !important;
  opacity: 0.6 !important;
}
.modal-open::before {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
}
.add-money-modal .prepend-input .input-group-prepend {
  border-color: var(--bs-border-color) !important ;
}
.add-money-modal .prepend-input .input-group-text {
  border: 0 !important;
}
/* Kyc Modal popup :: END */

/* pricing table popup css :: BEGIN */
.modal-header .btn-close {
  background-image: url('assets/images/close-icon.svg');
  position: absolute;
  right: -5px;
  top: -5px;
  opacity: 1;
  background-size: 22px;
  transition: all 0.2s linear;
}
table td i,
table td img {
  cursor: pointer;
}
.modal-header .btn-close:hover,
.modal-header .btn-close:focus {
  transform: scale(1.1);
  box-shadow: none;
}
.transparent-bg {
  background-color: transparent !important;
}
.pricing-table {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 0px;
}
.pricing-table .btn.btn-primary {
  box-shadow: 5px 5px 0 #0e509d;
  background-color: #fff !important;
  color: var(--primary-color) !important;
}
.pricing-table ul {
  padding-top: 25px;
  padding-bottom: 10px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  transition: all 0s linear;
  flex: 0 0 auto;
}
.pricing-table ul li:first-child::before {
  content: '';
  background-color: transparent;
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  height: 90px;
}
.pricing-table ul.active li:first-child::before {
  z-index: 1;
  border-radius: 50px;
  filter: blur(16px);
  box-shadow: 0px 0px 15px #157ffb;
  background: #157ffb45 0% 0% no-repeat padding-box;
}
.table-responsive {
  padding: 30px 0 0;
  overflow: auto;
}
.table tbody tr td,
.table tbody th td {
  text-align: left;
  border: 0 !important;
}
.pricing-table li span {
  cursor: pointer;
  position: relative;
  z-index: 5;
}
.pricing-table ul.w-150px {
  flex: 0 0 auto;
}
.pricing-table li {
  list-style: none;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  padding: 10px 15px;
  position: relative;
  align-items: center;
  gap: 5px;
  z-index: 3;
  height: 45px;
  justify-content: center;
}
.pricing-table ul:first-child li {
  border-top-left-radius: 4.5px;
  border-bottom-left-radius: 4.5px;
}
.pricing-table ul:last-child li {
  border-top-right-radius: 4.5px;
  border-bottom-right-radius: 4.5px;
}
.pricing-table ul.active {
  background-color: var(--primary-color);
  border-radius: 10px;
  z-index: 2;
}
.pricing-table ul.active li {
  background-color: var(--primary-color);
  color: #fff;
}
.table {
  width: 100%;
}
.border-remove {
  border:none !important;
}
.table td img {
  cursor: pointer;
}
.pricing-table li.table-header {
  font-weight: 600;
  height: 56px;
  line-height: 2.3;
  font-size: 16px;
}
/* pricing table popup css :: END */

.select2-container .select2-selection--single {
  display: flex !important;
  align-items: center !important;
}
.bulk-action-select .table-right-side-menu .select2-container {
  width: 200px !important;
}
/* Wallet Modal css :: BEGIN */
.prepend-input .input-group-prepend {
  border-right: 0 !important;
}
.prepend-input .form-control {
  border-left: 0;
  border-right: 1px solid var(--bs-border-color);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.prepend-input .input-group-text,
.prepend-input .input-group-prepend {
  border-radius: var(--bs-border-radius) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 0px solid var(--bs-border-color) !important;
  display: flex;
  align-items: center;
  background-color: var(--bg-color-input) !important;
  color: #000 !important;
}
.fa-search {
  transform: rotate(90deg);
  color: #535353;
  padding-bottom: 8px;
}
/* .input-group  input{
  padding-left: 0 !important;
} */
/* Wallet Modal css :: END */

/* Payment modal css :: BEGIN */
.payment-opt-selection label {
  width: 100px;
  height: 100px;
  border: 1px solid #ccd2d8;
  border-radius: 4.5px;
  position: relative;
  margin: 0 20px 0 0;
  text-align: center;
  line-height: 5.5;
  cursor: pointer;
}
.payment-opt-selection label img {
  width: 75px;
  height: 50px;
  object-fit: contain;
}
.payment-opt-selection.fancy-checkbox
  input[type='checkbox']:checked
  + span:before {
  line-height: 16px;
}
.payment-opt-selection.fancy-checkbox input[type='radio'] + span {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}
.payment-opt-selection.fancy-checkbox input[type='radio'] {
  display: none;
}
.payment-opt-selection.fancy-checkbox
  input[type='checkbox']:checked
  + span:before,
.payment-opt-selection.fancy-checkbox
  input[type='radio']:checked
  + span:before {
  line-height: 15px;
  background: #49c5b6;
  border-color: #49c5b6;
}
.payment-opt-selection.fancy-checkbox input[type='checkbox'] + span:before,
.payment-opt-selection label input,
.payment-opt-selection.fancy-checkbox input[type='radio'] + span:before {
  position: absolute;
  right: 0px;
  top: -10px;
  background: #fff;
  width: 17px;
  height: 17px;
  font-family: FontAwesome;
  content: '\f00c';
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: 3px;
  line-height: 16px;
}
.detailBox {
  border: 1px solid #ccd2d8;
  padding: 20px;
  border-radius: 10px;
}
.detailBox .small-text {
  display: flex;
  align-items: start;
  gap: 10px;
}
.detailBox strong {
  flex: 0 0 120px;
  text-transform: capitalize;
}
.detailBox .small-text:first-child span {
  text-transform: capitalize;
}
.divider-set {
  height: 2px;
  width: 100%;
  background: #5f6377;
  margin: 20px 0 15px;
}
.transfer-payment-modal .modal-content,
.transfer-payment-modal .modal-body {
  padding: 0;
}
.modal-body-section {
  padding: 15px 25px;
}
.transfer-payment-modal .modal-header {
  padding: 15px 25px 0;
}
.transfer-payment-modal .modal-footer {
  padding-left: 25px;
  padding-right: 25px;
}
/* Payment modal css :: END */

/* Header css :: BEGIN */
.user-account .user-photo {
  width: 40px !important;
  height: 40px;
  object-fit: contain;
  border-radius: 50% !important;
  border: 0 !important;
}
.user-account .dropdown-toggle .user-photo {
  width: 30px !important;
  height: 30px;
}
.user-account .dropdown-toggle {
  position: relative;
}
#navbar-menu .user-account .dropdown-toggle::after {
  display: block !important;
  margin-left: 0px;
}
.user-account .dropdown-toggle .user-name {
  padding-right: 10px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-form .btn {
  top: 6px !important;
}
.user-account {
  margin: 0 !important;
}
.dropdown.lh-1 {
  line-height: 1.25 !important;
}
#navbar-menu .user-account {
  margin: 0 0 !important;
  background: var(--bg-color-input);
  padding: 6px 13px;
  border-radius: 14px;
}
.user-account a {
  display: flex;
  color: #757575 !important;
  align-items: center;
  word-break: break-all;
  white-space: normal;
  font-weight: 300;
  font-size: 14px;
  text-transform: capitalize;
}
.user-account.d-lg-flex .dropdown span {
  font-size: 20px;
  font-weight: 600;
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
  top: 54px !important;
  background-color: var(--bg-color-input) !important;
  box-shadow: 0px 1px 5px 0px #0094ff4d !important;
  border-radius: 10px;
  width: 230px !important;
  padding: 8px !important;
}
.user-account li.divider {
  border-bottom: 1px solid #444;
  margin: 10px 0;
}
.theme-selection .switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 15px;
  margin-bottom: 0;
  box-shadow: none;
  border-radius: 34px;
  margin-left: 10px;
  vertical-align: middle;
}
.theme-selection .switch input {
  opacity: 0;
  width: 50px;
  z-index: 9;
  display: block;
  position: relative;
  height: 20px;
  cursor: pointer;
}
.theme-selection .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-color-input);
  -webkit-transition: 0.4s;
  margin-left: 0 !important;
  transition: 0.4s;
  /* box-shadow: 0px 0px 6px #333; */
}
.navbar-nav ul.user-menu.menu-icon > li > a {
  color: #ffffff !important;
}
.theme-selection .switch:focus,
.theme-selection .switch:hover,
.theme-selection .switch:active {
  box-shadow: none !important;
}

.theme-selection .slider:before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  left: 0px;
  bottom: 2px;
  margin: auto 0;
  transition: 0.4s;
  box-shadow: none;
  background: url('assets/images/light-mode.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 22px;
}

.theme-selection a {
  display: flex !important;
  align-items: center;
}

.theme-selection input:checked + .slider {
  background-color: #b5b5b5;
}

.theme-selection input:focus + .slider {
  box-shadow: none;
}

.theme-selection input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
  background: url('assets/images/dark-mode.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}
.theme-selection .slider.round {
  border-radius: 34px;
}

.theme-selection .slider.round:before {
  border-radius: 50%;
}

.navbar-brand {
  padding: 0px 10px !important;
}
.navbar-fixed-top {
  top: 0 !important;
  height: 70px;
  position: fixed !important;
}
#left-sidebar {
  margin-top: 70px !important;
  padding-top: 10px !important;
  z-index: 100 !important;
  background-color: var(--gray-color-200) !important;
  height: 100% !important;
  overflow: hidden;
}
#main-content {
  margin-top: 50px !important;
  background-color: var(--bg-color);
  padding: 10px 40px 0 !important;
}
#left-sidebar .metismenu::-webkit-scrollbar {
  width: 5px !important;
}
/* Track */
#left-sidebar .metismenu::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
#left-sidebar .metismenu::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 10px;
}
.nav-height-auto {
  height: auto !important;
}
/* Header css :: END */

/* campaign css :: BEGIN */
.purchase-DID-popup .modal-footer {
  justify-content: center !important;
}
.action-lists img {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.fancy-radio input[type='radio'] + span i {
  vertical-align: middle;
  border-radius: 8px;
  position: relative;
  bottom: 1px;
  content: '';
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  display: inline-block;
}
.badge {
  border: 0 !important;
}
.react-dropdown-select.form-control {
  max-height: 100px !important;
  overflow-y: auto;
  min-height: 50px !important;
  overflow-x: hidden;
}
.table td,
.table th {
  padding: 15px !important;
  font-size: 14px;
  position: relative;
  vertical-align: middle;
}
.table th {
  font-weight: 600;
  text-transform: uppercase;
}
.w-100px {
  width: 100px !important;
}
.w-65px {
  width: 65px !important;
}
.w-250px {
  width: 250px !important;
}
/* .status-show {
  width: 49px;
  height: 22px;
  border-radius: 11px;
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.fancy-radio input[type='radio']:checked + span i:after {
  background-color: var(--primary-color) !important;
}
.fancy-radio input[type='radio']:checked + span i {
  border-color: var(--primary-color) !important;
}
.status-show .dot {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
  display: block;
  margin-right: 3px;
}
.status-show.active {
  background-color: #28a745;
  color: #fff;
}
.status-show.inactive {
  background-color: red;
  color: #fff;
}
.borderRadius-10 {
  border-radius: 10px !important;
}
.themeInput {
  background-color: var(--bg-color-input);
  border-radius: 10px;
  width: 150px;
  height: 45px;
  border: 1px solid var(--bg-color-input);
  font-size: 14px;
  padding: 5px 10px;
}
.btm-info {
  background-color: #157ffb !important;
  border-color: #157ffb !important;
}
.w-40 {
  width: 40px !important;
  line-height: 2.2;
  text-align: center;
}
.w-60 {
  width: 50px !important;
}
.w-60 img {
  width: 100% !important;
}
.btn-info:hover {
  opacity: 0.7;
}
.without-header .header {
  display: none !important;
}
.themeTabs .nav-tabs-new > li > a.active,
.themeTabs .nav-tabs-new > li > a.active:hover,
.themeTabs .nav-tabs-new > li > .nav-link.active,
.themeTabs .nav-tabs-new > li > .nav-link.active:hover {
  border: 0 !important;
  border-bottom: 1px solid var(--primary-color) !important;
  background: transparent;
  color: var(--dark-blue-color) !important;
}
.themeTabs .nav-tabs-new > li > a,
.themeTabs .nav-tabs-new > li > .nav-link {
  border: 0 !important;
  border-bottom: 1px solid transparent;
  font-size: 18px;
  color: var(--light-text-color);
  padding: 7px 10px;
  border-radius: 0;
  font-weight: 500;
}
.borderRadius5 {
  border-radius: 5px !important;
}
.childTabContent.tab-content {
  border: 0 !important;
  padding: 10px 0 0;
  box-shadow: none !important;
}
.childTabs li:first-child .nav-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.childTabs li:last-child .nav-link {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.childTabs.nav-tabs-new > li > a.active {
  border-bottom: 1px solid #157ffb !important;
  background: transparent;
  color: var(--dark-blue-color);
  border-radius: 0 !important ;
}
.childTabs.nav-tabs-new .card.theme-card {
  background-color: transparent;
  box-shadow: none !important;
}
.tab-pane .body.table-responsive {
  padding: 0;
  border: 0;
}
.theme-cyan .btn-info {
  background: #157ffb !important;
  border-color: #157ffb !important;
  color: #ffffff !important;
}
.gray-box {
  background-color: var(--bg-color);
  border-radius: 4.5px;
  padding: 0 !important;
}
.green-btn {
  background-color: #28a745 !important;
  border: 1px solid #28a745 !important;
  color: #fff;
}
.green-outline-btn {
  color: #6d757d !important;
  background-color: transparent !important;
  border: 1px solid #28a745 !important;
}
.btn-opt-formate .btn {
  border-radius: 13px;
  width: 81px;
  height: 26px;
  line-height: 1;
}
.create-target .modal-content {
  padding: 0;
}
.create-target .tab-content {
  margin-bottom: 0;
}
.create-target .modal-body {
  padding: 0 10px;
}
.create-target.modal.show .modal-dialog {
  top: 0;
  transform: none !important;
}
.create-target label {
  font-size: 18px;
  font-weight: 400 !important;
  color: #5f6377 !important;
}
.create-target.modal .modal-header {
  border-bottom: 0 !important;
  padding: 15px 25px !important;
}
.create-target .form-control {
  height: 45px !important;
  border-radius: 5px;
}
.create-target .modal-header .btn-close {
  background-image: url('assets/images/close-img.png');
  position: relative;
  right: auto;
  top: auto;
  opacity: 1;
  background-size: 14px;
  transition: all 0.2s linear;
}
.icon-question {
  font-size: 14px;
}
.themeTabs .tab-pane {
  padding: 5px 20px 15px;
}
.target-table td {
  padding: 5px;
  position: relative;
}
.target-table tr td:first-child {
  padding: 5px 0;
  width: 100px;
}
.target-table tr td:nth-child(2) {
  width: 100px;
}
.set-table-error-msg .formik-error {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 200px;
}
.set-table-error-msg {
  height: 30px;
}
.set-label-margin label {
  margin-bottom: 0;
}
.target-table {
  /* width: 100%; */
}
.target-table .switch {
  margin-top: 5px;
}
.route-add .themeTabs .tab-pane {
  padding: 5px 12px;
}
.padding-12 {
  padding: 0 12px;
}
table td.break-settd {
  padding: 0 !important;
}
.breaktime-display {
  background-color: #50c5b6;
  color: #fff !important;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
}
.breaktime-display .remove-break {
  cursor: pointer;
  scroll-margin-left: 5px;
}
.gray-box table td {
  background-color: transparent;
}
.modal-body p.mb-0 {
  margin-bottom: 0 !important;
}
.create-target h6 {
  font-size: 20px;
  color: #5f6377;
  font-weight: 500;
}
/* campaign css :: END */
.icon-close {
  font-family: 'Simple-Line-Icons' !important;
}
.previw-setup {
  position: relative;
  z-index: 99;
}
/* Route css :: BEGIN */
.fz-9 {
  font-size: 9px !important;
}
label {
  font-size: 16px;
  font-weight: 400 !important;
  color: #444444 !important;
}
.timepicker-section i {
  cursor: pointer;
}
.timepicker-section {
  width: 30px;
  text-align: center;
}
.table-selection .w-150px {
  width: 150px !important;
}
.react-dropdown-select-option {
  background: #fff !important;
  color: #444444 !important;
  border-radius: 4.5px !important;
}
/* Route css :: END */
/* Expenses css :: BEGIN */
.theme-card {
  border: 0 !important;
  border-radius: 4.5px !important;
}
.card-title {
  background: #157ffb;
  color: #fff !important;
  width: fit-content;
  padding: 7px 23px;
  border-radius: 5px 5px 0 0;
  font-size: 14px !important;
}
.theme-card .card-body {
  padding: 20px 25px !important;
}
input[type='date'] {
  -webkit-appearance: auto !important;
  appearance: auto !important;
}
input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('assets/images/calender-icon.svg') no-repeat;
  width: 37px;
  background-size: 30px 30px;
  height: 37px;
  border-width: 0;
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 40px;
}
.input[type='date'] {
  position: relative;
}
.opacity5 {
  opacity: 0.5;
}
.marketing-patform-list label {
  flex: 0 0 100px;
  margin-bottom: 5px;
}
.marketing-patform-list.gap-4 {
  gap: 5px !important;
}
/* Expenses css :: END*/
/* Did add numbers css :: BEGIN */
.switch-field {
  display: flex;
  margin-bottom: 5px;
  overflow: hidden;
}
.footer-center .modal-footer {
  justify-content: center !important;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #d9d9d9;
  color: #5f6377;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  padding: 10px 16px;
  margin-right: -1px;
  transition: all 0.1s ease-in-out;
  border: var(--bs-border-width) solid #fff;
}

.create-target .switch-field label {
  padding: 12px 16px;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #157ffb;
  border-color: #157ffb;
  box-shadow: none;
  color: #fff !important;
}

.switch-field label:first-of-type {
  border-radius: 8px 0 0 8px;
}

.switch-field label:last-of-type {
  border-radius: 0 8px 8px 0;
}
/* Did add numbers css :: END */
/* Reports css :: BEGIN */
.green-text {
  color: #28a745 !important;
}
.view-report table th {
  font-weight: 600 !important;
  color: #6d757d !important;
}

.view-report table th,
.view-report table td {
  font-size: 14px;
  padding: 10px;
}
.create-target.modal .modal-footer {
  padding: 15px 15px !important;
}
/* Reports css :: END */

/* Preview Modal css :: BEGIN */
.PreviewModal .modal-body {
  margin: auto;
}
.tooltip.show {
  z-index: 99999 !important;
  opacity: 1 !important;
}
/* Preview Modal css :: END */

/* Filter dropdown css :: BEGIN */
.filter-dropdown-data {
  width: 180px;
  background: #17191c;
  height: 300px;
  overflow-y: auto;
  text-align: left;
  padding: 15px;
  z-index: 3;
  position: absolute;
  top: 42px;
  left: 0px;
}
.filter-dropdown-data label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter-dropdown-data label span {
  color: #fff;
  width: 130px;
  word-break: break-all;
  font-size: 14px;
}
.table-right-side-menu,
.filter-dropdown {
  position: relative;
}
.filter-dropdown.w-40 {
  padding: 8px 0;
}
.table-right-side-menu .dropdown-menu {
  background: var(--filter-bg);
  border-radius: 5px;
  max-height: 247px;
  overflow: auto;
}
.table-right-side-menu .dropdown-menu .fancy-checkbox span {
  color: #fff;
}
.table-right-side-menu .dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.table-right-side-menu .dropdown-menu::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  cursor: pointer;
}

.table-right-side-menu .dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6d757d;
}

/* Filter dropdown css :: END */

.with-info {
  display: flex;
  align-items: center;
  font-size: 14px;
}
#tracks {
  font-size: 0;
  position: relative;
  text-align: center;
}

/* Settings css :: BEGIN */
.gradient-box {
  border-radius: 10px;
  padding: 50px 20px;
  color: #fff;
  box-shadow: 0px 10px 60px 0px #e4f4ff;
  background: rgb(8, 104, 217);
  background: linear-gradient(314.4deg, #00fbff 17.43%, #00ff85 91.89%);
}
.dropdown-toggle::after {
  display: none !important;
}
.btn-outline-white {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 10px !important;
  padding: 10px 15px !important;
  color: #333 !important;
  font-size: 14px !important;
}
.btn-outline-white:hover {
  background-color: #fff !important;
  color: var(--primary-color) !important;
}
.white-box .btn-sm {
  height: 30px;
  font-size: 12px !important;
  width: 100px;
}
.white-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  color: #6d757d;
  font-size: 14px;
}
.white-box p {
  margin-bottom: 0;
}
.white-box h2 {
  color: #333333;
  margin: 0;
}
.white-box strong {
  color: #333333;
  margin: 0;
  margin-left: auto;
  text-align: left;
  width: 230px;
  font-weight: 500 !important;
}
.wallet-status {
  margin-bottom: 10px;
}
.waltter-use-data {
  flex: 0 0 auto;
}
.live-status {
  background-color: #28a745;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.wallet-detail-show-section {
  display: block;
  margin-bottom: 10px;
}
/* Setting css :: END */

/* Loader css :: BEGIN */
.loader .loader-spin {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: var(--primary-color) var(--primary-color) transparent
    transparent;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.loader .loader-spin:after,
.loader .loader-spin:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #17191c #17191c;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-animation: rotationBack 0.5s linear infinite;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader .loader-spin:before {
  width: 12px;
  height: 12px;
  border-color: var(--primary-color) var(--primary-color) transparent
    transparent;
  -webkit-animation: rotation 1.5s linear infinite;
  animation: rotation 1.5s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.theme-cyan .page-loader-wrapper {
  background-color: #02020230 !important;
  font-weight: 700;
  letter-spacing: 0.2px;
}
.page-loader-wrapper p {
  color: #000000 !important;
}
.page-loader-wrapper .loader {
  top: 50% !important;
  transform: translateY(-50%);
}
/* Loader css :: END */

/* KYC request list css :: BEGIN */
.text-blue {
  color: #157ffb !important;
}
.table a {
  color: #6d757d;
  text-decoration: underline !important;
}
.table .text-center a {
  justify-content: center;
}
.pagignation-navbar {
  width: 100%;
}
.page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.btn-kyc {
  background-color: #f1f1f1 !important;
  color: #6d757d !important;
  border-radius: 14px;
  border: 1px solid #6d757d !important;
  padding: 3px 10px 7px !important;
  line-height: 1;
  font-size: 13px;
  margin-right: 5px;
}
.btn-kyc.approved,
.btn-kyc.approve:hover {
  background-color: #28a745 !important;
  color: #fff !important;
  border: 1px solid #28a745 !important;
}
.btn-kyc.under-reviewed,
.btn-kyc.under-review:hover {
  background-color: #b1b158 !important;
  color: #fff !important;
  border: 1px solid #b1b158 !important;
}
.btn-kyc.reject:hover,
.btn-kyc.rejected {
  background-color: #dc3545 !important;
  color: #fff !important;
  border: 1px solid #dc3545 !important;
}
.KYC-approve-modal .modal-footer {
  justify-content: flex-start !important;
  padding-left: 12px;
}
.KYC-approve-modal .modal-footer .btn-info {
  background-color: #50c5b6 !important;
  border-color: #50c5b6 !important;
}
.kyc-reject-comment {
  position: absolute;
  width: 300px;
  height: auto;
  max-height: 250px;
  background: #17191c !important;
  border-radius: 5px;
  top: 60px;
  padding: 10px !important;
  right: 20px;
  z-index: 2;
}
.kyc-approve-comment {
  height: auto !important;
  right: 100px !important;
}
.kyc-request-approve {
  height: auto !important;
  right: 100px !important;
}
.kyc-reject-comment p {
  color: #fff !important;
  margin-bottom: 20px !important;
  white-space: pre-wrap;
}
.kyc-reject-comment textarea {
  width: 100%;
  resize: none;
  padding: 1px 5px;
}
.modal.KYC-approve-modal.show .modal-dialog {
  top: 40%;
}
.kyc-reject-comment .btn-outline-secondary {
  background-color: #fff;
}
.kyc-reject-comment .triangle {
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 15px 12px 15px;
  border-color: transparent transparent #17191c transparent;
  transform: rotate(0deg);
  right: 20px;
  top: -9px;
}
.kyc-reject-comment .kyc-request-approve .triangle {
  right: 80px;
}
/* KYC request list css :: END */

/* KYC document list css :: BEGIN */
.accordian-table .main-table.table .noborder {
  border: 0 !important;
  padding: 0 !important;
}
.accordian-table .table-responsive {
  padding: 0;
}
.accordian-table a.primary-text {
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-size: 16px;
}
.accordian-table span.primary-text {
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-size: 16px;
}
.accordian-table table td i {
  cursor: default !important;
}
.accordion-button::after {
  background-image: none !important;
  content: '' !important;
  font: normal normal normal 20px/1 FontAwesome !important;
}
.accordian-table .accordion-button {
  border-radius: 5px;
  border-color: #ced4da;
}
.accordion-button:not(.collapsed) {
  box-shadow: 0px 6px 8px #ced4da !important;
  background-color: transparent !important;
  border-radius: 5px 5px 0 0;
}
.accordian-table td table td div {
  background-color: transparent !important;
}
.accordian-table td span {
  word-wrap: break-word;
  max-width: 200px;
  white-space: normal;
  line-height: 2;
  padding: 0 0 0 10px;
  background-color: transparent;
}
.accordion-body table td {
  text-align: center !important;
}
.accordion-item {
  border: #fff !important;
}
/* KYC document list css :: END */

/* Dashboard css :: BEGIN */
.info-text {
  color: #157ffb !important;
  font-weight: 700;
}
.theme-text {
  color: #50c5b6;
  font-weight: 700;
}
.calls-information h6 {
  font-size: 20px;
  line-height: 22px;
  color: var(--th-color);
}
.calls-information p {
  font-size: 12px;
  color: var(--light-text-color);
  font-weight: 400;
}
.date-range-section,
.chart-selection {
  display: flex;
  align-items: center;
  gap: 10px;
}
.refresh-chart {
  background-color: #6d757d;
  border-radius: 14px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  line-height: 1.4;
}
.refresh-chart i {
  transform: rotate(20deg);
}
.date-range-section input {
  position: relative;
}
.date-range-section input[type='date']::-webkit-calendar-picker-indicator {
  top: -1px;
  right: -5px;
  background-size: 36px 36px;
}
.chart-selection .form-control {
  background-color: #fff;
}
.numShow {
  font-size: 25px;
  line-height: 0;
  color: #444444;
  font-weight: 400;
}
.imageShow img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  transition: all 0.2s linear;
}
.cardData-section .card-body {
  font-size: 14px;
  border-radius: 10px;
  /* transition: all 0.2s linear; */
  min-height: 95px;
  display: flex;
  align-items: center;
}
.cardData-section .card-body.active,
.cardData-section .card-body:hover {
  background: transparent var(--background-gradient) no-repeat padding-box;
  color: #333 !important;
}
.cardData-section .card-body.active p,
.cardData-section .card-body:hover p {
  color: #333;
}
.cardData-section .card-body.active .numShow,
.cardData-section .card-body:hover .numShow {
  color: #333;
}
.cardData-section .card-body.active .imageShow img,
.cardData-section .card-body:hover .imageShow img {
  width: 45px;
  height: 45px;
}
.textDesc {
  color: #5f6377;
  font-size: 12px;
  margin: 5px 0;
}
.amountData {
  font-size: 20px;
  color: var(--black-color-700);
  font-weight: 600;
  margin: 2px 0px 0 5px;
}
.plan-parameter th {
  font-weight: 500;
}
.chart-selection .select2-container--default .select2-selection--single {
  padding: 2px 0;
  background-color: #fff !important;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 35px;
}
.chart-selection
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 4px;
}
.set-lh
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 5px;
}
.chart-selection
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  margin-top: 0;
}
.chart-selection
  .select2-container--default
  .select2-selection--multiple.select2-selection--clearable,
.chart-selection .select2-container--default .select2-selection--multiple {
  padding-top: 0;
  padding-left: 0;
  overflow: auto;
  height: auto;
  max-height: 100px;
}
.chart-selection .align-items-start label {
  margin-top: 5px;
  width: 94px;
}
.advertiser-dashboard-data .select2-selection.select2-selection--multiple {
  min-height: 45px;
  padding-left: 8px;
  border-radius: 10px;
}
.advertiser-dashboard-data .set-width {
  width: 49%;
}
.advertiser-dashboard-data
  .select2-container
  .select2-search--inline
  .select2-search__field {
  margin-top: 10px;
}
.advertiser-dashboard-data .chart-selection .align-items-start label {
  width: 105px;
  font-weight: 500 !important;
  color: var(--black-color) !important;
}
.btn-toggle-fullwidth i {
  font-size: 40px;
  color: var(--dark-blue-color);
}
.block-header .breadcrumb {
  display: none;
}
.chart-selection .select2-container--default .select2-selection--multiple {
  border: 0;
  padding-left: 7px;
}
.chart-date-section {
  gap: 10px;
}
.chart-selection
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  margin-top: 14px;
}
.set-width {
  width: 49%;
}
/* Dashboard css :: END */

/* Pramotional Plan css :: BEGIN */
.table.without-border th,
.table.without-border td {
  border: 0;
  padding: 5px !important;
}
.position-relative {
  position: relative;
}
.date-selection input[type='date']::-webkit-calendar-picker-indicator {
  top: 7px;
  right: -4px;
}
.w-200px {
  width: 200px !important;
}
.table.without-border .btn i {
  background-color: transparent !important;
  color: #fff !important;
  padding: 0;
}
.table .btn-danger {
  background-color: #dc3545 !important;
}
.table .btn:hover {
  background-color: #bb2d3b !important;
}
.number-data-input .input-symbol {
  position: absolute;
  top: 49%;
  left: 20px;
  font-weight: 700;
  transform: translateY(-50%);
}
.number-data-input .d-flex {
  background-color: transparent;
}
.number-data-input .form-control {
  width: 100px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  height: 50px;
  padding-left: 37px;
  padding-right: 5px;
  font-weight: 400;
}
.breadcrumb {
  align-items: center;
}
.number-data-input .form-control.w-65px {
  width: 65px;
  padding-left: 15px !important;
}
.number-data-input select.form-control {
  padding-left: 10px;
}
.auth-box .lead {
  font-size: 18px !important;
  font-weight: 300 !important;
}
/* Pramotional Plan css :: END */

/* Advertiser css :: BEGIN */
.status-show {
  width: 100%;
  padding: 1px 10px 3px;
  color: #fff;
  height: 22px;
  border-radius: 11px;
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio-controls-show img {
  width: 18px;
  height: 18px;
}
.status-show.success {
  background-color: #28a745;
}
.status-show.rejected {
  background-color: #dc3545;
}
.status-show.pending {
  background-color: #50c5b6;
}
.set-status {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgray' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -5px;
  margin-right: 2rem;
  width: 105px;
}
.set-status option {
  color: #212529;
}
/* Advertiser css :: END */
/* Payment data css :: BEGIN  */
.upload-payment-logo {
  position: absolute;
  top: 0px;
  height: 38px;
  width: 80px;
  right: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}
.btn-gray {
  background-color: #f1f1f1 !important;
  color: #444444 !important;
}
.payment-method-box {
  background-color: transparent;
  border-radius: 5px;
  padding: 0px;
}
.upload-preview-section {
  display: flex;
  align-items: start;
  gap: 10px;
}
.input-group.upload-preview-section .btn {
  position: relative;
  border-radius: 5px !important;
}
.breadcrumb-item.active a {
  color: var(--bs-breadcrumb-item-active-color);
}
.themeRadio .fancy-radio input[type='radio']:checked + span i:after {
  background-color: #50c5b6 !important;
}
.themeRadio .fancy-radio input[type='radio']:checked + span i {
  border-color: #50c5b6 !important;
}
/* Payment data css :: END */
/* Platform admin dashboard css :: BEGIN */
.card-no-shadow .card-body,
.card-no-shadow .card {
  box-shadow: none;
}
.card-no-shadow .card-body p {
  margin-bottom: 0;
}
.card-no-shadow .card-body .d-flex p:first-child {
  flex: 0 0 63%;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}
.card-no-shadow .card-body .numShow {
  color: var(--primary-color);
  font-weight: 700;
}
/* .card-no-shadow ,.card-body:hover .d-flex p:first-child{
  color: #fff;
} */
/* Platform admin dashboard css :: END */

/* Wire transfer css :: BEGIN */
.settableHeight {
  min-height: 390px;
}
.table-data-selection {
  border: 1px solid #ced4da;
  padding: 5px 13px;
  border-radius: 5px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}
.full-dark .table-data-selection {
  color: #ced4da !important;
}
.table-right-side-menu {
  flex-wrap: wrap;
}
.hidefilter .filter-dropdown {
  display: none !important;
}
/* Wire transfer css :: END */
/* carrier css :: BEGIN */
.block-header .btn-toggle-fullwidth {
  margin-top: 0 !important;
}
.block-header h2 {
  display: flex;
  align-items: center;
  gap: 0px;
  position: relative;
  color: var(--th-color);
  font-size: 20px !important;
}
.box-setup {
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 160px;
  height: 200px;
  overflow: auto;
  padding: 5px 8px;
}
.action-field i {
  background-color: #ced4da;
  color: #fff;
  padding: 3.8px;
  text-align: center;
  border-radius: 5px;
  display: block;
  margin-bottom: 2px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.action-field i:hover {
  background-color: #6d757d;
}
/* carrier css :: END */

/* Global configuration css :: BEGIN */
table .btn.btn-primary:hover {
  background-color: var(--bs-btn-hover-bg) !important;
  border-color: var(--bs-btn-hover-border-color) !important;
}
/* Global configuration css :: END */

/* Sidebar css :: BEGIN */
#main-menu li .hoverICon {
  display: none;
}
#main-menu li.active .hoverICon,
#main-menu li:hover .hoverICon {
  display: block;
}
#main-menu li.active .mainICon,
#main-menu li:hover .mainICon {
  display: none;
}
/* Sidebar css :: END */
/* Permission denied css :: BEGIN */
.divCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.permission-denied {
  height: 75vh;
}
.permission-denied i {
  font-size: 30px;
} /* Permission denied css :: END */
.cardSet {
  background-color: #fff;
  border-radius: 5px;
  height: 88vh;
}
.plan-parameter h6 {
  width: 120px;
  background-color: transparent !important;
  white-space: normal;
  padding: 0 8px !important;
  margin: 0;
}
/* .plan-parameter .number-data-input .form-control{
  padding-left: 15px;
} */
.bg-white {
  background-color: white !important;
}
.advertiser-plan-select {
  border: none !important;
  background-color: transparent !important;
}
.kyc-status {
  border-radius: 100px !important;
  padding: 8px !important;
}
.btn-success {
  background-color: #28a745 !important;
  color: white !important;
}
.status-pending {
  color: #50c5b6;
}
.kyc-status-pending {
  color: #157ffb !important;
}
.text-bg-pending,
.full-dark .text-bg-pending {
  color: white !important;
  background-color: #50c5b6;
}
.text-bg-suspended,
.full-dark .text-bg-suspended {
  color: white !important;
  background-color: #50c5b6;
}
.text-bg-under-review,
.full-dark .text-bg-under-review {
  color: white !important;
  background-color: #b1b158 !important;
}
.suspended {
  color: #157ffb;
}
.fancy-checkbox .chekbox-set {
  display: flex !important;
  align-items: center;
}
.fancy-checkbox .chekbox-set::before {
  flex: 0 0 24px;
  line-height: 24px !important;
}
.fancy-checkbox .chekbox-set p {
  margin: 0;
}
.right-side-nav.nav.navbar-nav {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: row;
}
.navbar-nav ul.notifications {
  background: #e4f3ff !important;
  width: 500px !important;
  /* overflow: auto; */
}
.navbar-nav ul.notifications::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--bg-color-input);
  position: absolute;
  top: -11px;
  right: 15px;
}
.navbar-nav ul.notifications li {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 10px;
  border: 0 !important;
}
.navbar-nav ul.notifications li.header {
  padding-bottom: 10px !important;
}
.navbar-nav ul.notifications li > a {
  padding: 0 !important;
}
.navbar-nav ul.notifications li.footer a {
  padding-top: 0 !important;
}
.navbar-nav ul.notifications.no-more-data {
  padding-bottom: 5px;
}
.notification-set .counter {
  position: absolute;
  top: -14px;
  right: 1px;
  min-width: 21px;
  width: max-content;
  height: 21px;
  background: red;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 1;
  padding: 0 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#navbar-menu .user-account.notification-set {
  padding: 10px 15px;
}
/* .plan-data-show .table thead tr th:last-child{
  text-align: left !important;
} */
.table thead tr th:last-child,
.table tbody tr td:last-child {
  text-align: center;
}
.table tbody tr td:last-child .d-flex {
  justify-content: center;
}
.promotional-plan .select2-container--default {
  width: 100% !important;
}
.form-data
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow,
.select2-selection--multiple .select2-selection__arrow {
  top: 4px;
  right: 5px;
}
.advertiser-filter .select2-container--default {
  width: 200px !important;
}
.card .header.d-flex {
  row-gap: 10px;
}
.card .header.d-flex .table-right-side-menu {
  margin-left: auto;
  display: flex;
}
.block-header {
  z-index: 2;
  position: relative;
}
.form-data .select2-container--default .select2-selection--single,
.select2-selection--multiple {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: var(--bs-body-color);
  background-color: var(--bg-color-input);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bg-color-input);
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 45px !important;
  border-radius: 10px !important;
}
.form-data
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: var(--bs-border-width) solid var(--bg-color-input);
}
.form-data .select2-container--default .select2-selection--multiple {
  border: var(--bs-border-width) solid var(--bg-color-input) !important;
  max-height: 100px;
  overflow-y: scroll;
  scrollbar-width: none !important;
  height: auto;
  min-height: 45px;
  border-radius: 10px;
  padding-left: 6px;
  padding-top: 0;
  background-color: var(--bg-color-input);
}
.form-data
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 28px;
  padding-left: 0;
}
.DID-list .select2-container {
  width: 100% !important;
}
.select-error-set .formik-error,
.lh-set .formik-error {
  margin-top: 15px;
}
/* toaster css :: BEGIN */
#toast-container > div {
  width: 100% !important;
}
#toast-container .toast-info {
  background-color: #49c5b6 !important;
}
#toast-container > div:hover {
  box-shadow: none !important;
}
.full-dark .toast-header .btn-close {
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat !important;
  color: #b0b0bb !important;
}
.pointer-events-none {
  pointer-events: none;
}
.red {
  color: red;
}
.green {
  color: green;
}
.display-none {
  display: none !important;
}
.kyc-reject-comment p.text-error {
  color: red !important;
}
/* toaster css :: END */
.accordian-header-set h2 button {
  padding: 15px 10px 15px 0 !important;
}
.accordion-button:focus,
.accordion-button:not(.collapsed),
.accordion-button.collapsed,
.accordion-button.collapsed:focus {
  outline: none !important;
  border-color: inherit !important;
  box-shadow: none !important;
  background-color: transparent;
}
.themeInput.dropdown-toggle.btn {
  padding: 5px !important;
}
.accordian-table .main-table.table tr:hover .noborder,
.accordian-table .main-table.table tr:hover {
  background-color: transparent !important;
}
.accordian-table .main-table.table tr:hover .noborder {
  --bs-table-bg-state: transparent !important;
  cursor: pointer;
}
.kyc-actions .fa-trash {
  cursor: pointer !important;
}
.view-all:hover,
.view-all:focus {
  color: #6d757d !important;
  text-decoration: underline !important;
}
.kyc-data-show-modal .modal-header {
  padding-left: 30px;
}
.kyc-data-show-modal .kyc-user-name,
.modal-body .kyc-user-name {
  word-break: break-all;
  margin-bottom: 10px !important;
  font-weight: 700;
  padding-left: 15px;
  text-transform: capitalize;
  color: var(--primary-color);
}
.kyc-document-show-modal .download-opt {
  position: absolute;
  right: 20px;
  top: -27px;
  border-radius: 50%;
  background: var(--primary-color);
  padding: 10px;
  color: #fff;
  transition: all 0.2s linear;
}
.kyc-document-show-modal .download-opt:hover {
  transform: scale(1.1);
}
.kyc-document-show-modal img {
  max-width: 500px;
  object-fit: scale-down;
  width: auto;
}
.advertiser-name {
  word-break: break-all !important;
  margin-bottom: 0;
}
.sidebar-scroll .user-account {
  padding-left: 20px;
}
table td p {
  margin-bottom: 0 !important;
}
table .required-document {
  color: #157ffb !important;
  text-decoration: none !important;
}
table .document {
  color: #157ffb !important;
  text-decoration: none !important;
}
.required-document::after {
  padding: 2px;
  content: ' *';
  color: red;
  margin-bottom: 3px;
}
.wallet-balnce h2 {
  font-size: 28px;
  color: #333333;
}
.ckeditor-section h6 {
  height: 20px;
  background: #f3f3f3;
  border: 1px solid var(--ck-color-base-border) !important ;
  border-radius: 0 0 3px 3px !important;
  margin-bottom: 25px;
  font-size: 12px !important;
}
.ck.ck-content.ck-editor__editable {
  height: 150px !important;
  border-bottom: 0 !important;
}
.draft-color {
  color: #157ffb;
}
.publish-color {
  color: #28a745;
}
.unread-notification {
  background-color: #f4f7f6 !important;
}
.notification-text {
  white-space: normal !important;
  width: 100% !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.notification-html span {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap;
  word-break: break-word;
  column-gap: 6px;
}
.notification-html a {
  color: blue !important;
  text-decoration: underline !important;
}
.notification-html a:hover {
  color: blue !important;
}
.code-item:hover {
  color: white;
  background-color: var(--primary-color) !important;
  cursor: pointer;
}
.code-item {
  padding: 2px;
}
.select-code {
  color: white;
  background-color: var(--primary-color) !important;
  cursor: pointer;
}
.white-list-ip-data h6 {
  margin-top: 12px;
  margin-bottom: 20px;
}
.btn-box .btn-sm {
  margin-bottom: 0 !important;
  /* width: 35px !important; */
  height: 35px !important;
}
.white-list-ip-data .amount-section {
  width: 84%;
}
.carrier-type.info-text {
  font-weight: 400 !important;
  color: #157ffb !important;
}
.modal-body p.terms-content {
  height: 400px;
  overflow: auto;
  margin-bottom: 0 !important;
  margin-top: 15px;
}
.modal-body p.terms-content P:last-child {
  margin-bottom: 0px !important;
}
.modal-body p.terms-content .table {
  overflow: auto;
  width: 100%;
  max-height: 300px;
}
.modal-body p.terms-content .table table td,
.modal-body p.terms-content .table table th {
  white-space: normal;
}
#rememberme {
  margin-top: 5px;
}
.text-error {
  font-size: 0.9em !important;
  color: rgb(252, 60, 60) !important;
  font-weight: 400 !important;
  transition: all 0.2s ease 0s !important;
}
.disabled-nav {
  cursor: none !important;
  pointer-events: none !important;
  opacity: 0.3 !important;
  color: black !important;
}
.disabled-nav a:hover {
  background-color: #f1f1f1 !important;
  color: black !important;
}
.theme-daterange {
  position: absolute;
  right: 50px;
  top: 3px;
}
.datepickerrange-input {
  background-image: url('assets/images/calender-icon.svg');
  background-repeat: no-repeat;
  background-position: 97%;
  width: 100%;
  cursor: pointer;
  caret-color: transparent;
  position: relative;
  z-index: 1;
}
.react-datepicker__input-container .datepickerrange-input {
  width: 240px;
}
.react-datepicker__input-container .cdr-filter-datepicker {
  width: 270px !important;
}
.react-datepicker__close-icon::after {
  position: relative;
  z-index: 10;
  display: block !important;
  font-size: 13px !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 2px;
  background: var(--primary-color) !important;
  padding: 0px 3.5px !important;
  line-height: 16px !important;
}
.react-datepicker__close-icon {
  width: 40px;
  right: 10.3px !important;
  top: 1px !important;
}
.notes-fonts {
  font-size: 13px;
}
textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.user-profile {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.user-profile svg {
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px auto;
  border-radius: 50%;
}
.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
  padding: 0px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
}
/* logo changes css advertiser */
.no-image-logo {
  position: relative;
  width: 80px;
  height: 40px;
}
.no-image-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
  padding: 0px;
  position: relative;
  z-index: 2;
}
.logo-btns{
 background-color:  #333333 !important ;
 border-color: #333333 !important ;
 color: #fff !important;
}
/* .user-profile::after{
  background-color: #02020287;
  content: "\e095";
  font-family: "Simple-Line-Icons", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: var(--primary-color);
  z-index: 3;
  cursor: pointer;
  display: none;
} */
.profile-action-btn {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.profile-action-btn .btn-primary {
  position: relative;
}
.profile-upload-input {
  position: absolute;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.remove-user-profile {
  position: absolute;
  right: 8px;
  top: -2px;
  z-index: 9;
  color: white;
  background: red;
  padding: 0px 5px 1px;
  border-radius: 60%;
  cursor: pointer;
  font-size: 13px;
}
.remove-logo-profile{
  position: absolute;
    right: -3px;
    top: -8px;
    z-index: 9;
    color: white;
    background: red;
    padding: 0px 5px 1px;
    border-radius: 60%;
    cursor: pointer;
    font-size: 13px;
}
.user-profile:hover::after {
  display: none;
}
.user-profile .form-control {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100% !important;
  opacity: 0;
  z-index: 10;
}
.rhap_stacked .rhap_controls-section {
  display: none;
}
.rhap_progress-filled {
  background: #157ffb !important;
  border-radius: 10px !important;
}
.rhap_progress-indicator {
  top: -6px !important;
  border: 3px solid #ffffff;
  box-shadow: none !important;
  background-color: #6d757d !important;
}
.rhap_container {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 5px !important;
}
.audio-controls-show button {
  background-color: transparent;
  border: 0;
  padding: 3px;
}
.audio-controls-show button:hover {
  opacity: 0.7;
}
.rhap_progress-section {
  width: 140px !important;
  position: relative;
  height: 40px;
}
.view-report .rhap_progress-section {
  height: auto;
  width: 110px !important;
}
.rhap_progress-bar {
  height: 8px !important;
  border-radius: 10px !important;
}
.rhap_time {
  position: absolute;
  bottom: -5px;
  left: 6px;
  color: #6d757d !important;
  font-size: 12px !important;
}
.rhap_time.rhap_total-time {
  right: 6px;
  left: auto;
}
.view-report .rhap_time {
  font-size: 10px !important;
  bottom: -15px;
}
.rhap_download-progress {
  border-radius: 30px !important;
}
.view-report .audio-controls-show {
  display: flex;
  align-items: center;
}
.view-report .audio-controls-show button {
  padding: 0 3px;
  line-height: 0;
}
.view-report .rhap_progress-container {
  margin: 0 !important;
}
.rhap_play-status--playing .rhap_progress-indicator {
  background-color: #157ffb !important;
}
.button-disabled {
  pointer-events: none !important;
  opacity: 0.2 !important;
}
.global-config-data select.form-control {
  background-color: #fff;
}
.date-range-section label {
  width: 130px;
}
.advertiser-dashboard-data .date-range-section label {
  width: 130px;
  font-weight: 500 !important;
  color: var(--black-color) !important;
}
#reportrange {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff !important;
  border: 0;
  border-radius: 10px;
}
.wallet-balnce label {
  color: #333333 !important;
}
.img-circle {
  border-radius: 50%;
  object-fit: fill;
  width: 25px;
  height: 25px;
  font-size: 36px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plan-data-show table td div.gap-2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.chart-name {
  font-size: 16px;
  color: #17191c;
  font-weight: 600;
  transform: rotate(-90deg);
  width: fit-content;
  position: absolute;
  left: -15px;
  top: 38%;
}
.with-addon .input-group-text {
  background-color: #fff !important;
}
.credit-set th label {
  font-size: 14px;
  font-weight: 600 !important;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
}
.credit-set .date-selection,
.credit-set .amount-section,
.credit-set p {
  background-color: transparent !important;
  padding: 0 !important;
}
.wi-refresh {
  cursor: pointer;
}
.marketing-platform-tooltip {
  margin-bottom: 0 !important;
}
.global-config-section table td {
  text-align: left !important;
}
.marketing-patform-section .text-error {
  margin-left: 73px;
}
.other-paltform-section .text-error {
  margin-left: 130px;
}
.preview-btn:hover,
.preview-btn:disabled {
  color: white !important;
}
.upload-preview-section label {
  max-width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 10px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 4px !important;
}
.full-dark .chart-name,
.full-dark .accordion-body table td span {
  color: #b0b0bb;
}
.full-dark .select2-container--default .select2-selection--multiple,
.full-dark .input-group-text,
.full-dark .prepend-input .input-group-prepend,
.full-dark .form-data .select2-container--default .select2-selection--single,
.full-dark .select2-selection--multiple,
.full-dark .accordion-item,
.full-dark .select2-dropdown,
.full-dark .select2-search--dropdown .select2-search__field,
.full-dark .ck.ck-editor__main > .ck-editor__editable,
.full-dark .ckeditor-section h6,
.full-dark .ck.ck-toolbar,
.full-dark .prepend-input .input-group-prepend {
  color: #333;
  background-color: #fff !important;
  border-color: #fff !important;
}
.full-dark .page-item.active .page-link {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.full-dark .page-item.disabled .page-link {
  background-color: #141414 !important;
  border: 1px solid #fff;
}
.page-item:not(:first-child) .page-link {
  border-radius: 0 !important;
}
.pagination li.page-item:last-child .page-link {
  border-radius: 0px 5px 5px 0 !important;
}
.full-dark .pagination span {
  color: #fff !important;
}
.full-dark .metismenu .collapse.in {
  background: #424242;
}
.full-dark .sidebar-nav .metismenu ul a::before {
  border-color: #fff;
}
.full-dark .sidebar-nav .metismenu > li .active a {
  background: #424242;
}
.full-dark .sidebar-nav .metismenu ul li.active a::before {
  border-color: #fff;
}
.full-dark .sidebar-nav .metismenu ul li.active a::after {
  background-color: #fff;
}
.full-dark .react-datepicker__header,
.full-dark .react-datepicker {
  background-color: #333333;
  /* border-color: #333333 !important; */
  color: #fff;
}
.full-dark
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.full-dark
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: #2b2b35;
}
.full-dark .pricing-table li {
  color: #333333;
  background-color: #fff;
  border-color: #fff !important;
}
.full-dark .advertiser-dashboard-table .table,
.full-dark .advertiser-dashboard-table .table th,
.full-dark .advertiser-dashboard-table .table td {
  background: transparent !important;
}
.full-dark .react-datepicker__current-month,
.full-dark .react-datepicker-time__header,
.full-dark.react-datepicker-year-header,
.full-dark .react-datepicker__day-name,
.full-dark .react-datepicker__day,
.full-dark .react-datepicker__time-name {
  color: #b0b0bb;
}
.full-dark .modal-header .btn-close {
  background-color: transparent !important;
}
.full-dark
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #495057;
}
.full-dark .react-datepicker__day--disabled,
.full-dark .react-datepicker__month-text--disabled,
.full-dark .react-datepicker__quarter-text--disabled,
.full-dark .react-datepicker__year-text--disabled {
  color: #495057;
}
.react-datepicker__navigation-icon--previous::before {
  top: 13px !important;
  background: transparent !important;
}
.full-dark .react-datepicker__navigation {
  background-color: transparent !important;
}
::placeholder {
  color: #acacac;
}
.full-dark .kyc-data-show-modal td a.text-blue,
.full-dark .kyc-data-show-modal td img,
.full-dark .kyc-data-show-modal td .d-flex {
  background-color: transparent !important;
}
.full-dark .themeTabs .nav-tabs-new > li > a.active,
.full-dark .themeTabs .nav-tabs-new > li > a.active:hover,
.full-dark .themeTabs .nav-tabs-new > li > .nav-link.active,
.full-dark .themeTabs .nav-tabs-new > li > .nav-link.active:hover {
  color: #fff !important;
}
input::placeholder {
  color: #acacac !important;
}
.full-dark .kyc-data-show-modal td a.text-blue {
  color: #b0b0bb !important;
}
.hint-text {
  opacity: 0.7;
  font-style: italic;
}
.navbar-fixed-top .navbar-brand img {
  height: 50px;
  object-fit: contain;
  width: 160px !important;
  margin: auto;
}
.navbar-fixed-top .navbar-brand {
  flex: 0 0 230px;
  margin: auto;
}
.navbar-fixed-top .navbar-brand a {
  width: 100%;
  margin: auto;
  text-align: center;
}
.navbar-fixed-top .container-fluid {
  padding-left: 0 !important;
}
.auth-box .helper-text a {
  justify-content: center;
}
.word-keep {
  word-break: break-word !important;
}
.chart-body {
  position: relative !important;
  height: 450px;
}
.chart-loader {
  display: flex;
  /* background-color: #dddd; */
  justify-content: center;
  text-align: center;
  margin: auto;
  align-items: center;
  width: 100%;
  position: absolute;
  height: 100%;
}
.kyc-info i {
  background-color: white !important;
  color: #444 !important;
}
.kyc-under-review {
  color: #8b8b25 !important;
}
.attach-document-title {
  background-color: transparent !important;
}
.kyc-document-show-modal .modal-content .modal-body {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.kyc-data-show-modal .btn-download {
  background: #49c5b6;
  border-color: #49c5b6;
  padding: 5px 8px;
  height: 25px;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  text-decoration: none !important;
}
.kyc-data-show-modal .btn-info {
  padding: 5px 8px;
  height: 25px;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  text-decoration: none !important;
}
.kyc-data-show-modal .btn-download svg {
  margin: auto;
}
.kyc-data-show-modal .btn-download svg path,
.kyc-data-show-modal .btn-info svg path {
  fill: #fff !important;
}
.did-number-listing table td:nth-child(5),
.did-number-listing table th:nth-child(5) {
  /* text-align: center !important; */
}
.role-list-table table td:first-child {
  width: 85%;
}
.navbar-nav ul.notifications li .text {
  word-break: break-word;
}
.btn-div-td {
  background-color: transparent !important;
}
.full-dark .input-group-text.btn-primary {
  background-color: #49c5b6 !important;
  border-color: #49c5b6 !important;
  color: #fff !important;
}
.full-dark .upload-section .kyc-info i {
  background-color: transparent !important;
  color: #49c5b6 !important;
}
.profile-image {
  border-radius: 50px;
  object-fit: cover;
}
.kyc-data-show-modal .modal-body {
  max-height: 400px;
  overflow: auto;
}
.cdr-remark::first-letter {
  text-transform: capitalize;
}
.cdr-remark {
  padding-left: 70px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  line-height: 1.8 !important;
}
.echarts-for-react svg text {
  font-family: var(--fonts) !important;
}
.barchart svg text {
  fill: #5f6377 !important;
}
/* New figma changes :: BEGIN */
.fz-12 {
  font-size: 12px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 1px !important;
}
.addroles-data table td,
.addroles-data table th {
  color: var(--dark-blue-color);
  font-size: 18px;
}
.select2-container--default .select2-selection--single {
  background-color: var(--bg-color-input) !important;
  height: 45px !important;
  border-color: var(--bg-color-input) !important;
  border-radius: 10px !important;
  line-height: 40px !important;
  padding-left: 5px;
}
.did-number-add
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 2px !important;
}
.plan-valid {
  color: var(--filter-bg);
  font-size: 14px;
}
.btn-outline-info {
  font-weight: 700;
  border-width: 2px;
}
.fancy-checkbox input[type='checkbox'] + span:before {
  border-color: var(--primary-color) !important;
  border-radius: 5px !important;
}
.block-header h2::before {
  content: '/';
  top: 9px;
  left: 25px;
  font-size: 20px;
  position: absolute;
  color: var(--th-color);
}
.btn-toggle-fullwidth svg path {
  stroke: var(--dark-blue-color);
  fill: #f4faff;
}
.block-header h2 a {
  margin-right: 5px;
}
.revenue-header {
  font-weight: 600;
}
.navbar-nav ul.user-menu.menu-icon > li > a,
.navbar-nav ul.user-menu.menu-icon > li > p {
  color: var(--gray-color-700) !important;
  background: var(--white-color);
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 14px;
  border: 1px solid var(--white-color);
}
.navbar-nav ul.user-menu.menu-icon > li > a:hover,
.navbar-nav ul.user-menu.menu-icon > li > p:hover {
  background-color: var(--white-color);
  border-color: var(--primary-color);
}
.theme-selection .choose-skin {
  margin-bottom: 0;
  color: var(--gray-color-700) !important;
  background: var(--white-color);
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
}
.navbar-nav ul.user-menu.menu-icon > li > a svg,
.navbar-nav ul.user-menu.menu-icon > li > p svg {
  fill: var(--primary-color);
}
.navbar-nav ul.user-menu.menu-icon > li > p span {
  margin-left: 7px;
}
.user-menu::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--bg-color-input);
  position: absolute;
  top: -11px;
  right: 15px;
}
.static-data-box {
  background-color: var(--white-color);
  border-radius: 20px;
  box-shadow: 0px 10px 20px 0px #e4f4ff;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-evenly;
}
.static-data {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 10px;
}
.line-seprater {
  width: 1px;
  height: 100px;
  background-color: #f0f0f0;
}
.static-data-box .static-data:last-child:after {
  display: none;
}
.static-num {
  font-weight: 500;
  font-size: 24px;
}
.switch input:disabled + span {
  pointer-events: none;
  opacity: 0.6;
}
.theme-cyan .btn-primary {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}
.revenue-value .flex-set {
  flex: 0 0 45%;
}
.add_icon img {
  cursor: pointer;
}
.card {
  box-shadow: var(--card-box-shadow) !important;
  border: 0 !important;
  border-radius: 10px !important;
}
.right-side-nav .bold,
.static-num {
  color: var(--th-color);
}
.themeInput.dropdown-toggle.btn.green-btn {
  color: var(--white-color);
  background-color: var(--green-color) !important;
  border-radius: 10px;
}
.table-right-side-menu .refresh-chart {
  border-radius: 10px;
  font-size: 38px;
  line-height: 1px;
}
.theme-cyan .btn {
  border-radius: 10px;
  text-transform: capitalize;
}
.card .header h2 {
  font-size: 18px !important;
  color: var(--dark-blue-color) !important;
}
.sidebar-nav .metismenu > li {
  padding: 0 10px;
}
.sidebar-nav .metismenu > li a {
  border-left: 0 !important;
  border-radius: 8px;
}
.theme-cyan .sidebar-nav .metismenu > li.active > a,
.theme-cyan .sidebar-nav .metismenu > li:hover > a {
  border-left: 0 !important;
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 8px;
}
table .text-bg-danger {
  color: var(--white-color) !important;
  background-color: var(--red-color) !important;
}
table .text-bg-success {
  color: var(--white-color) !important;
  background-color: var(--green-color-300) !important;
}
table .badge {
  font-weight: 400;
  border-radius: 6px;
  padding: 7px 10px 6px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  font-size: 12px;
  margin: 0;
}
.theme-cyan .fancy-checkbox input[type='checkbox']:checked + span:before {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.fancy-checkbox input[type='checkbox']:checked + span:before {
  font-size: 16px !important;
}
.metismenu .collapse.in {
  background: var(--bg-color-input);
  border-radius: 8px;
}
.sidebar-nav .metismenu ul a::before {
  content: '' !important;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--filter-bg);
}
.sidebar-nav .metismenu ul a::after {
  content: '';
  width: 5px;
  height: 5px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  left: 23px;
}
.sidebar-nav .metismenu ul li.active a::before {
  border-color: var(--primary-color);
}
.sidebar-nav .metismenu > li .active a {
  color: var(--primary-color);
  font-weight: 500 !important;
  background-color: var(--bg-color-input);
}
.metismenu .active > .has-arrow::after,
.metismenu .has-arrow[aria-expanded='true']::after {
  color: var(--white-color) !important;
}
.sidebar-nav .metismenu ul li.active a::after {
  background-color: var(--primary-color);
}
.sidebar-nav .metismenu a {
  font-size: 14px;
}
.pagination span {
  color: var(--dark-blue-color);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination li:first-child .page-link {
  border-radius: 5px 0 0 5px;
}
.pagination .page-item:last-child .page-link {
  border-radius: 0 5px 0 5px;
}
.pagination {
  padding-right: 0 !important;
}
.pagination .page-link {
  width: auto;
  height: 34px;
}
form label {
  font-size: 18px;
  color: var(--dark-blue-color);
  line-height: 27px;
  letter-spacing: -0.5px;
}
.form-label {
  font-size: 16px;
  color: var(--dark-blue-color) !important;
  line-height: 27px;
  letter-spacing: -0.5px;
}
.card-tab-header {
  padding: 10px 16px;
}
.card-tab-header ul {
  border-bottom: 1px solid var(--breadcrum-border-color);
  padding: 0 10px 15px;
}
.b-none .card-tab-header ul {
  border-bottom: 0px !important;
  padding: 0 10px 15px;
}
.publisher-nav {
  float: right;
  width: 60%;
}
.publisher-nav .right-side-nav{
  justify-content: end !important;
}
.custom-select-dropdown .select2-container--default .select2-selection--single .select2-selection__arrow b {
   left: 50%;
  position: absolute;
  top: 50%;
  width: 0;
  transform: translateY(50%);
}
.btn-secondary {
  background-color: var(--dark-blue-color) !important;
  border-color: var(--dark-blue-color) !important;
  color: var(--white-color) !important;
}
.themeTabs.card {
  padding: 0px 10px 20px;
}
.themeTabs .nav-link img {
  display: none;
}
.card-tab-header .nav-tabs-new > li > a:hover,
.card-tab-header .nav-tabs-new > li > a:focus,
.card-tab-header .nav-tabs-new > li > .nav-link:hover,
.card-tab-header .nav-tabs-new > li > .nav-link:focus {
  background-color: transparent !important;
  color: #acacac !important;
}
.modal-body .themeTabs .tab-pane {
  padding: 5px 12px 15px;
}
.modal-body .card {
  box-shadow: none !important;
}
.table thead th {
  border-bottom: 0;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
}
.table.border-remove-th thead th {
  border-top: 0 !important;
}
.table tbody td {
  color: #696d80;
  font-size: 15px;
  font-weight: 400;
}
.auth-main .card {
  box-shadow: none !important;
}
.auth-main .input-group input {
  padding-left: 0.75rem !important;
}
.themeTabs .tab-content {
  padding: 0 15px 15px;
}
.working-hr-set label {
  margin-bottom: 0;
}
.route-add .card-tab-header {
  padding: 10px;
}
.route-add .themeTabs.card {
  padding: 0px 15px 20px;
}
#bacicTab2pan-3 h6 {
  color: #5f6377;
  font-size: 18px;
  font-weight: 400;
}
.did-selection-camp .select2-container--default .select2-selection--single {
  line-height: 30px !important;
}
.did-selection-camp
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  display: contents !important;
}
.themeTabs .nav-tabs-new.switch-tab-ui > li > a,
.themeTabs .nav-tabs-new.switch-tab-ui > li > a:hover {
  background-color: #d9d9d9;
  color: #5f6377;
  font-size: 18px;
  font-weight: 400;
  border: 0 !important;
}
.themeTabs .nav-tabs-new.switch-tab-ui > li:first-child > a {
  border-radius: 8px 0 0 8px !important;
}
.themeTabs .nav-tabs-new.switch-tab-ui > li:last-child > a {
  border-radius: 0px 8px 8px 0 !important;
}
.themeTabs .nav-tabs-new.switch-tab-ui > li > a.active,
.themeTabs .nav-tabs-new.switch-tab-ui > li > a.active:hover {
  background-color: var(--primary-color);
  color: #fff !important;
}
.did-selection-camp label {
  font-size: 18px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 31px !important;
  color: #acacac !important;
}
.preview-users {
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.welcome-message{
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #333333;
}
.features-paragraph{
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.01em;
  color: #333333;
}
.version-update {
  color: #838383;
  font-size: 10px;
  font-weight: 500;
  margin-left: 5px;
}
.portal-version-data {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
}
.social-media-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: #000;
  font-weight: 500;
}
.social-media-link a {
  text-decoration: none;
}
.portal-version-data svg {
  fill: #000;
  margin-right: 5px;
}
.sidebar-bottom {
  padding: 0 10px;
  text-align: center;
}
.sidebar-bottom .social-media-link img {
  width: 20px;
  height: 20px;
}
.sidebar-nav .metismenu {
  height: calc(100vh - 180px);
  overflow: auto;
}
.advertiser-sidebar .sidebar-nav .metismenu {
  height: calc(100vh - 210px);
  overflow: auto;
}
.metismenu .has-arrow:after {
  right: 8px !important;
}
textarea {
  background-color: var(--bg-color-input);
  border-color: var(--bg-color-input);
  color: #acacac !important;
}
.kyc-status-success {
  border: 0 !important;
  padding: 0 !important;
  color: var(--green-color) !important;
  background-color: transparent !important;
}
.accordian-table .accordion-item {
  border: 0 !important;
}
.accordian-table .accordian-header-set {
  padding: 10px 20px;
  border-radius: 10px !important;
  border: 1px solid #ddd !important;
  margin-bottom: 10px;
}
.accordian-table .accordion-collapse {
  border: 1px solid #ddd;
  border-radius: 10px !important;
  margin-bottom: 10px;
}
.accordian-table .accordion-collapse th {
  border: 0 !important;
}
.accordian-table .accordion-collapse tr th:first-child,
.accordian-table .accordion-collapse tr td:first-child {
  text-align: left !important;
}
.accordian-table table {
  margin-bottom: 0;
}
.kyc-status-rejected {
  border: 0 !important;
  background-color: transparent !important;
  color: var(--red-color) !important;
}
.audio-controls-show svg path,
.audio-controls-show svg rect {
  stroke: #333;
  fill: none;
}
.audio-controls-show svg rect.circle-strop {
  fill: #333;
}
.daterangepicker {
  border-radius: 10px !important;
}
.daterangepicker .ranges ul {
  padding: 10px !important;
}
.daterangepicker .ranges li {
  font-family: var(--fonts) !important;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
}
.full-dark .daterangepicker .ranges li.active,
.daterangepicker .ranges li.active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.set-lh
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 41px !important;
}
.chart-data-drops .select2-container {
  width: 100% !important;
  margin-left: 15px !important;
}
.full-dark .add-money-modal .prepend-input .input-group-prepend {
  background-color: #fff !important;
  border-color: #333 !important;
}
body.full-dark .add-money-modal .prepend-input .input-group-text {
  background-color: #fff !important;
  border: none !important;
}
.cardData-section .card-body .d-flex {
  width: 100%;
}
.theme-cyan ::selection {
  color: #ffffff;
  background: var(--primary-color) !important;
}
.pagignation-list {
  display: flex;
}
.live-calls-show {
  margin-right: 12px;
}
.dropdown.user-notification {
  margin-left: 15px;
}
.add_icon {
  margin-right: 10px;
}
/* publisher-page :: css starts */

.select2-container--open .select2-dropdown--below {
  border-radius: 10px !important;
  padding: 10px;
  border: 2px solid #f2fafe !important;
  box-shadow: 0px 10px 10px 0px #f2fafe !important;
  margin-top: 5px !important;
}
.select2-container--open .select2-dropdown--above {
  border-radius: 10px !important;
  padding: 10px;
  border: 2px solid #f2fafe !important;
  box-shadow: 0px 10px 10px 0px #f2fafe !important;
}
.full-dark .select2-container--open .select2-dropdown--below {
  box-shadow: 0px 1px 10px 0px #e4f4ff49 !important;
}
.full-dark .select2-container--open .select2-dropdown--above {
  box-shadow: 0px 1px 10px 0px #e4f4ff49 !important;
}
.bg-gray {
  background-color: #ccc !important;
}

.live-call-dropdown {
  display: inline-flex;
  flex-direction: column;
  background: #eee;
  border-radius: 12px;
  padding: 1.5rem 1rem 0 2rem;
}
.full-dark .live-call-dropdown {
  background: #424242;
}
.full-dark .react-datepicker__month-dropdown,.full-dark .react-datepicker__year-dropdown {
  color: #7c7272;
}

.campaign-subform-bg {
  background: #eee;
  padding: 1rem;
  border-radius: 15px;
}
.full-dark .campaign-subform-bg {
  background-color: #424242;
}
.cdr-dropdown-container {
  display: grid !important;
  grid-template-columns: repeat(2, auto);
  background: #eee;
  border-radius: 12px;
  padding: 1.5rem 1rem 0 2rem;
}
.full-dark .cdr-dropdown-container {
  background-color: #424242;
}
.add-publisher-container input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #0596ff;
  cursor: pointer;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-publisher-container input[type='checkbox']:checked {
  background-color: #0596ff;
  position: relative;
  color: white;
}
.add-publisher-container input[type='checkbox']:checked::after {
  content: '✓';
  color: white;
  font-size: 18px;
}

.publisher-list-container input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #0596ff;
  cursor: pointer;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.publisher-list-container input[type='checkbox']:checked {
  background-color: #0596ff;
  position: relative;
  color: white;
}
.publisher-list-container input[type='checkbox']:checked::after {
  content: '✓';
  color: white;
  font-size: 18px;
}

/* publisher-page :: css ends */

#advertiser_status,
.advertiser-plan-select {
  -webkit-appearance: none;
  background-image: url('../src/assets/images/arrow-down.png');
  background-position: center right;
  background-size: 8px 8px;
  padding: 0 20px 0 0;
  background-repeat: no-repeat;
}
.revenue-header p {
  font-size: 22px;
  font-weight: 600;
}
.echarts-for-react {
  padding-left: 10px;
}
.select2-results__option {
  padding: 10px;
}
.container-fluid .container-fluid {
  padding: 0;
}
.notification-table table th:nth-child(3) {
  width: 250px;
}
.welcome-text {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
}
.user-account .m-uname .advertiser-name {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
}
.sidebar-scroll .user-account.d-lg-none {
  padding: 0 15px;
}
.sidebar-scroll .user-account.d-lg-none a {
  background-color: #e4f3ff;
  padding: 5px 15px 8px;
  border-radius: 10px;
}
.timezone-section {
  width: 210px !important;
}
.filterSet-chart {
  background-color: #e4f3ff;
  border-radius: 5px;
  padding: 4px 10px 5px;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #acacac;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.filterSet-chart .fa-times {
  color: #ffffff;
  background-color: var(--primary-color);
  font-size: 13px;
  padding: 1px 2px;
  border-radius: 3px;
}
.filter-charts-set {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
}
.charts-tab .nav-tabs .nav-item {
  background: #e4f3ff;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
.charts-tab .nav-tabs .nav-item a {
  color: #5f6377;
  font-size: 16px;
  font-weight: 400;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
.charts-tab .nav-tabs .nav-item.active,
.charts-tab .nav-tabs .nav-item a.active {
  background-color: #5f6377 !important;
  color: #ffffff !important;
}
.charts-tab .nav-tabs .nav-item:first-child a,
.charts-tab .nav-tabs .nav-item:first-child {
  border-radius: 5px 0 0 5px;
}
.charts-tab .nav-tabs .nav-item:last-child a,
.charts-tab .nav-tabs .nav-item:last-child {
  border-radius: 0 5px 5px 0;
}
.charts-tab .nav-tabs {
  border-bottom: 0;
}
.chart-show-section {
  padding: 0 15px;
}
.chart-show-section .row {
  border-top: 1px solid #c6c6c6;
  padding-top: 5px;
  margin: 20px 10px;
}
.timezone-section .select2-container--default .select2-selection--single {
  height: 40px !important;
}
.timezone-section
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 28px !important;
}
.chart-filter-selection .btn-primary,
.full-dark .chart-filter-selection .btn-primary {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border: 0 !important;
  padding: 0;
}
.chart-filter-selection .dropdown-menu .filterName {
  padding: 0px 15px;
  color: #acacac;
  font-size: 16px;
  height: 30px !important;
}
.chart-filter-selection .dropdown-menu .filterName:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.dataInclude {
  display: flex;
  align-items: center;
  gap: 10px;
}
.selectedFilter-droparea {
  padding: 10px;
}
.filterSet-chart .dropdown .btn-primary {
  background-color: transparent !important;
  color: #5f6377 !important;
  border: 0 !important;
  padding: 0;
}
.delete-filterSelection {
  margin-right: 4px;
}
.delete-filterSelection svg {
  fill: var(--primary-color);
  cursor: pointer;
}
.filterSet-chart .dropdown {
  display: flex;
  align-items: center;
}
.filterSet-chart .dropdown-menu.show {
  left: -25px !important;
  margin-top: 5px;
  padding: 10px;
  min-width: 200px;
}
.filterSet-chart .dropdown-menu select {
  width: 100%;
  border: 0;
}
.filterSet-chart .dropdown-menu select option {
  padding: 5px 10px;
}
.filterSet-chart .dropdown-menu select option:hover {
  background-color: var(--primary-color) !important;
}
.charts-tab .nav {
  width: fit-content;
}
.charts-tab .tab-content {
  margin-top: -30px;
}
.profile-upload-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 10px;
}
.notes-text-client {
  color: var(--red-color);
  font-size: 12px;
  text-align: right;
  font-weight: 500;
  margin-bottom: 3px;
}
.lh-set
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  height: 40px;
  line-height: 40px !important;
}
.lh-set
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 5px !important;
}
.charts-tab .nav-tabs {
  position: relative;
  z-index: 2;
}
.table-right-side-menu .btn {
  height: 40px !important;
}
.filterSet-chart .select2-container .select2-selection--single {
  height: 30px !important;
  padding: 0 !important;
  line-height: 25px !important;
}
.filterSet-chart
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0 !important;
  height: 30px !important;
  line-height: 25px !important;
}
.filterSet-chart
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: -1px;
  line-height: 31px !important;
}
.daterangepicker .ranges li:hover {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.accordian-table .set-border {
  border-top: 1px solid var(--bs-border-color);
  padding-top: 10px;
}
select option,
select * {
  background-color: #fff !important;
  min-height: 40px !important;
}
.about-work-note {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}
.about-work-note p {
  font-size: 14px;
}
.input-group > .form-control.w-100px {
  flex: 0 0 100px !important;
}
.input-group .input-group-append .input-group-text#callLength-data {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.set-label-center label {
  display: block;
  width: 100%;
}
.charts-section-bg .col-12 {
  align-self: stretch;
}
.charts-section-bg .col-12 .card {
  height: 100%;
}
.commentIconModal {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.lh-set
  .select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  height: 42px;
}
.call-summey-repoert-table table tr td:last-child,
.call-summey-repoert-table table tr th:last-child,
.ar-data-show table tr td:last-child,
.ar-data-show table tr th:last-child,
.thr-data-show table tr td:last-child,
.thr-data-show table tr th:last-child {
  text-align: left;
}
.auth-box .lead.fz-14 {
  font-size: 14px !important;
}
.down-arrow-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-top: -30px;
}
.mobile-scroll {
  height: 100%;
  position: relative;
  padding-bottom: 20px;
}
.down-arrow-animation .chevron {
  position: absolute;
  width: 26px;
  height: 7px;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
  color: #fff;
}
.down-arrow-animation .chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}
.down-arrow-animation .chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}
.down-arrow-animation .chevron:before,
.down-arrow-animation .chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #fff;
}
.down-arrow-animation .chevron:before {
  left: 0;
  transform: skewY(30deg);
}
.down-arrow-animation .chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}
@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}
.page-not-found {
  position: relative;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
}
.page-not-found img.error-img {
  max-width: 100%;
  margin: auto;
  width: 300px;
  object-fit: contain;
}
.text-section {
  width: 310px;
}
.text-section p,
.text-section h1,
.text-section h5 {
  color: #333333;
}
.page-not-found h3 {
  color: #333;
}
.select2-selection--multiple:before {
  content: '';
  position: absolute;
  right: 7px;
  top: 42%;
  border-top: 5px solid #858585;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.no-arrow .select2-selection--multiple:before {
  display: none;
}
.show-data-arrow .select2-selection--multiple:before {
  display: block;
}
.filterTable-data thead th {
  background-color: var(--primary-color);
  color: #fff;
  text-transform: capitalize;
  padding: 7px 20px !important;
}
.filterTable-data td {
  padding: 7px 20px !important;
}
.border-set {
  border-top: 1px solid #ddd;
  width: 96%;
  margin: 10px auto 0;
}
.table tr th:first-child,
.table tr td:first-child {
  padding-left: 0 !important;
}
.filterTable-data .table thead th:first-child,
.filterTable-data .table tbody td:first-child {
  padding-left: 20px !important;
}
.filterTable-data .table tbody td:last-child {
  text-align: left;
  width: 155px;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #acacac;
}
.filter-charts-set
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: -5px;
  top: 0px;
}
.signup-data-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 43px !important;
}
.signup-data-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px;
}
.dropdown-menu-end {
  top: 28px !important;
  transform: none !important;
  right: -3px !important;
  left: auto !important;
}
.filterTable-data .table tbody td.child-td {
  border-bottom: 1px solid #c6c6c6 !important;
  padding: 0 !important;
}
.filterTable-data .table tbody td.child-td .pagignation-list {
  padding-right: 1rem !important;
}

.filterTable-data .child-table thead th {
  background-color: transparent;
  color: #333333;
}
.upload-preview-section label {
  line-height: 2;
}
.advertiser-dashboard-table .table > :not(caption) > * > * {
  background-color: transparent !important ;
  border: 0;
}
.advertiser-dashboard-table .body.table-responsive {
  padding-top: 0;
}
.advertiser-dashboard-table .table > th {
  border-top: 0 !important;
}
.filterTable-data.pdfShow {
  overflow: visible;
}
.filterTable-data.pdfShow table td,
.filterTable-data.pdfShow table th {
  padding: 2px !important;
  font-size: 10px !important;
}
.modal-body-section.add-block {
  padding: 5px 15px 0px;
}
.modal-body-section.add-block p {
  margin-bottom: 8px !important;
}
.pdfSet {
  background-color: #fff !important;
}
.pdfSet .cdr-report-data {
  box-shadow: none !important;
}
.pdfSet .filterTable-data .child-table {
  padding-bottom: 0;
}
.pdfSet .filterTable-data .child-table td {
  padding: 1px 20px !important;
}
.footer-center .modal-footer {
  justify-content: center !important;
}
.dark-blue-text {
  color: var(--dark-blue-color) !important;
}
.login-box {
  margin-top: -40px;
}
.daily-cap-input td:nth-child(2) {
  display: none;
}
.target-table.cap-selection td:nth-child(3) {
  width: 100px;
}
.image-sliders .image-gallery {
  width: 100%;
}
.image-gallery-slide {
  margin-right: 10px;
}
.image-gallery-slide img {
  display: block;
  width: 99% !important;
  margin: auto;
  border-radius: 15px;
}
.call_length .input-group .formik-error {
  flex: 0 0 100%;
  margin: 15px 0 0;
}
.target-table tr td:first-child label {
  width: 200px;
}
.vertical-align-middle.signup-data-form {
  display: block !important;
  height: auto;
}
.vertical-align-wrap.sign-up-section {
  display: flex !important;
  align-items: flex-start;
  height: auto;
}
.auth-box.login-box {
  margin-top: 10px;
}
.table.plan-parameter tr th:first-child,
.table.plan-parameter tr td:first-child {
  padding-left: 15px !important;
}
.set-close-btn {
  display: block;
  height: 32px !important;
  width: 40px;
  line-height: 1.4 !important;
}
.advance-search-btn.btn-link {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: var(--dark-blue-color);
  text-decoration: none;
}
.toggle-arrow {
  width: 10px;
  vertical-align: middle;
  margin-left: 15px;
  transition: all 0.2s linear;
}
.toggle-arrow.flip {
  transform: rotate(-180deg);
}
.header-center .modal-header .modal-title {
  margin: auto;
}
.width-set {
  width: 60%;
}
.h-45px {
  height: 45px;
}
/* figma changes :: END */
/* signup page css :: START */
.signup-data-form.auth-main::after {
  background-image: none;
  background-color: var(--bg-color);
}
.about-us {
  display: block;
  padding-left: 100px;
  width: 55%;
  margin-top: 108px;
}
.sign-up-section .about-us {
  margin-top: 93px;
}
.signup-data-form.auth-box {
  margin-top: 15px;
}
.btn-primary.btn-padding {
  padding: 10px 35px;
}
/* signup page css :: END */
/* Helpdesk css :: START */
.box-section {
  box-shadow: 0px 10px 60px 0px #e4f4ff;
  background: #ffffff;
  padding: 40px !important;
  border-radius: 20px;
}
body.full-dark .box-section {
  background: #333333 !important;
  box-shadow: unset !important;
}
.box-section .card {
  border-radius: 30px !important;
  padding: 40px 30px 15px 30px;
  min-height: 390px;
  max-height: fit-content;
  width: 90%;
  position: relative;
  box-shadow: unset !important;
}
.box-section .card.first-box {
  background-color: #31a7e8 !important;
}
.box-section .card.second-box {
  background-color: #41c9e2 !important;
}
.box-section .card.third-box {
  background-color: #88d7c9 !important;
}
.box-section .card h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
}
.box-section .card img {
  margin-bottom: 15px !important;
}
.box-section .card ul li,
.box-section .card .note {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #fff;
  margin-bottom: 5px;
}
.box-section .card button {
  height: 40px;
  width: 90%;
  text-align: center;
  background-color: #fff !important;
  color: #000 !important;
  bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  border-radius: 20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
/* Helpdesk css :: END */
/* Dark theme css :: BEGIN */
body.full-dark {
  color: #fff !important;
  background: #141414 !important;
  height: 100vh;
}
.full-dark .btn-toggle-fullwidth svg path {
  stroke: #fff;
  fill: #141414;
}
.full-dark .loader p {
  color: #b0b0bb !important;
}
.white-text {
  color: #b0b0bb;
}
body.full-dark .user-account .user-name,
.full-dark .card,
.full-dark .card-body,
.full-dark .card .header,
.full-dark .modal-header .modal-title,
.full-dark .white-box h2,
.full-dark .white-box strong,
.full-dark label,
.full-dark .cardData-section .card .card-body p {
  color: #fff !important;
}
.full-dark .card-no-shadow .card-body:active .d-flex p:first-child {
  color: #fff !important;
}
.full-dark .table-right-side-menu .dropdown-menu {
  background: #2b2b35db;
}
.full-dark .refresh-chart i {
  background-color: transparent;
  color: #fff;
  border: 0;
}
.full-dark .refresh-chart {
  background-color: #5f6377;
}
.full-dark .disabled-nav {
  color: #acacac !important;
}
.full-dark .cardData-section .card-body.active p,
.full-dark .modal-body p {
  color: #fff;
}
.full-dark .select2-container--default .select2-selection--single {
  background-color: #fff !important;
}
.full-dark .table-right-side-menu .dropdown-menu .fancy-checkbox span,
.full-dark .user-account a {
  color: #b0b0bb !important;
}
.full-dark select.form-control {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="&%2310; fill: %23B0B0BB;&%2310;"><path d="m0,6l12,12l12,-12l-24,0z"/><path fill="none" d="m0,0l24,0l0,24l-24,0l0,-24z"/></svg>');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 7px 7px;
  background-origin: content-box;
}
.full-dark .amountData {
  color: #fff !important;
}
.full-dark .kyc-reject-comment p {
  color: #b0b0bb !important;
  box-shadow: none !important;
}
.full-dark .kyc-reject-comment textarea,
.full-dark textarea {
  background-color: #30303a;
  border-color: #3b3b46;
  color: #7f7f8e;
}
.full-dark .create-target h6 {
  color: #fff;
}
.full-dark .number-data-input .input-symbol {
  color: #acacac;
}
body.full-dark div .form-control,
body.full-dark .btn-default,
body.full-dark .custom-select,
body.full-dark .custom-file-label,
.full-dark .date-range-section input,
.full-dark .form-control,
.full-dark select option,
.full-dark .form-control:focus,
.full-dark textarea {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #acacac !important;
}
body.full-dark div .input-group-text {
  background-color: #ededed !important;
  color: #141414 !important;
  border-color: #ededed !important;
  font-size: 18px;
  padding: 5px 10px;
}
body.full-dark .append-transparent div .input-group-text {
  background-color: #fff !important;
  color: #acacac !important;
}
body.full-dark .advertiser-dashboard-data .form-control#reportrange,
body.full-dark
  .chart-selection
  .select2-container--default
  .select2-selection--multiple,
body.full-dark .chart-selection textarea,
.full-dark
  .chart-selection
  .select2-container--default
  .select2-selection--single {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
  box-shadow: 0px 10px 60px 0px #000000;
}
body.full-dark .form-control#reportrange {
  color: #acacac !important;
  background-color: #333 !important;
  border-color: #333 !important;
}
body.full-dark .btn-secondary.secondary-importbtn {
  background-color: #5f6377 !important;
  border-color: #5f6377 !important;
  color: #fff !important;
}
.full-dark
  .chart-selection
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #acacac !important;
}
.full-dark .daterangepicker .ranges li {
  color: #333 !important;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  color: #333;
}
.daterangepicker .drp-buttons .btn.cancelBtn {
  background-color: transparent !important;
  border: 0;
}
.full-dark
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__display {
  color: #333;
}
.full-dark .audio-controls-show svg path,
.full-dark .audio-controls-show svg rect {
  stroke: #fff;
}
.full-dark .audio-controls-show svg rect.circle-strop {
  fill: #fff;
}
body.full-dark div .page-link {
  background-color: transparent !important;
  color: #fff !important;
}
.full-dark td.number-data-input .d-flex {
  color: #fff;
}
.full-dark .fancy-checkbox input[type='checkbox'] + span:before {
  background-color: #fff;
}
.full-dark .btn-kyc.approved,
.full-dark .btn-kyc.approve:hover {
  border: 1px solid #28a745 !important;
}
.full-dark .btn-kyc.under-reviewed,
.full-dark .btn-kyc.under-review:hover {
  border: 1px solid #b1b158 !important;
}
.full-dark .form-control::placeholder {
  color: #acacac !important;
}
.full-dark .btn-kyc.reject:hover,
.full-dark .btn-kyc.rejected {
  border: 1px solid #dc3545 !important;
}
.full-dark .card,
.full-dark .cardSet {
  background: #333 !important;
  box-shadow: 0px 10px 60px 0px #000000 !important;
}
.full-dark #collapseExampleopne .card.shadow-none {
  box-shadow: none !important;
}
.full-dark .card.tab-content {
  box-shadow: none !important;
}
.full-dark .user-photo {
  color: #acacac;
}
.full-dark .gray-box,
.full-dark .navbar-nav ul.notifications {
  background: #141414 !important;
  box-shadow: 0px 10px 60px 0px #0000004d !important;
}
.full-dark .create-target .gray-box {
  background-color: #424242 !important;
}
.full-dark .create-target .gray-box table,
.full-dark .create-target .gray-box .table th,
.full-dark .create-target .gray-box .table td {
  background-color: #424242 !important;
  color: #fff !important;
}
.full-dark .table,
.full-dark .table td,
.full-dark .react-dropdown-select-option {
  background-color: #333 !important;
  color: #acacac !important;
}
.full-dark .table th {
  background-color: #333 !important;
  color: #fff !important;
}
.full-dark .table td svg path,
.full-dark .table td .btn-danger svg path {
  fill: #acacac;
}
.full-dark .btn-danger svg path {
  color: #fff;
}
body.full-dark #main-content .card .header h2 {
  color: #fff !important;
}
.full-dark .table td svg {
  color: #acacac;
}
.full-dark .table td .btn-danger svg path {
  fill: #fff;
}
body.full-dark .modal-content {
  background-color: #333 !important;
}
.table td svg {
  cursor: pointer;
}
.table td svg.comment-show {
  fill: #696d80;
}
.table td svg path {
  fill: #000000;
}
.table td .btn-danger svg path,
.btn-danger svg path {
  fill: #fff;
}
.full-dark .themeInput {
  background-color: #fff;
  border-color: #fff;
  color: #acacac !important;
}
.full-dark .gradient-box {
  box-shadow: 0px 10px 60px 0px #0000004d;
}
.full-dark .wallet-balnce label {
  color: #333333 !important;
}
.full-dark .white-box {
  background-color: #333333;
  border-color: #333333;
}
body.full-dark .navbar-fixed-top {
  top: 0;
}
.full-dark .navbar-right .form-control {
  border-color: #fff !important;
  color: #acacac !important;
}
.full-dark .navbar-right .form-control::placeholder {
  color: #fff !important;
}
.full-dark .block-header h2,
.full-dark .calls-information h6 {
  color: #fff;
}
.input-group:focus .input-group-prepend {
  border-color: #86b7fe !important;
}
.full-dark .btn-primary,
.full-dark.theme-cyan .btn-info {
  color: #fff !important;
}
.full-dark .switch-field label {
  background: #fff;
  color: #acacac !important;
  border-color: #141414;
}
.full-dark .btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  transition: all 0.2s linear;
}
.full-dark .btn-primary:hover,
.full-dark .btn-primary:active {
  opacity: 0.7;
}
body.full-dark .btn-outline-secondary,
body.full-dark .btn-secondary {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #141414 !important;
}
.full-dark .green-btn {
  background-color: #28a745 !important;
  border: 1px solid #28a745 !important;
  color: #fff;
}
.full-dark .green-outline-btn {
  /* color: #6d757d !important; */
  background-color: transparent !important;
  border: 1px solid #28a745 !important;
}
.full-dark .btn-outline-white {
  background-color: #fff !important;
  color: #141414 !important;
}
.full-dark .plan-valid {
  color: #acacac;
}
.full-dark .btn-outline-white:hover {
  background-color: #fff !important;
  color: var(--primary-color) !important;
}
.full-dark .btn-outline-info {
  color: #0dcaf0 !important;
}
.btn-outline-info:hover {
  color: #fff !important;
}
.full-dark .breadcrumb-item.active,
.full-dark .breadcrumb-item + .breadcrumb-item::before,
.full-dark .breadcrumb-item.active a {
  color: #acacac;
}
.full-dark .btn-gray {
  background-color: #141414 !important;
  color: #acacac !important;
}
.full-dark .payment-method-box {
  background-color: transparent;
}
.full-dark .accordian-table .accordion-item {
  border-color: #2b2b35 !important;
  background-color: transparent !important;
  color: #fff !important;
}
.full-dark .right-side-nav .bold,
.full-dark .static-num,
.full-dark .block-header h2 a {
  color: #ffffff;
}
.full-dark .user-menu::before,
.full-dark .navbar-nav ul.notifications::before {
  border-bottom: 12px solid #141414;
}
.full-dark.theme-cyan .navbar-nav ul.menu-icon,
.full-dark #navbar-menu .user-account {
  background-color: #141414 !important;
  box-shadow: 0px 10px 60px 0px #0000004d !important;
}
.full-dark .navbar-nav ul.user-menu.menu-icon > li > a,
.full-dark .navbar-nav ul.user-menu.menu-icon > li > p,
.full-dark .theme-selection .choose-skin {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
}
.full-dark .navbar-nav ul.user-menu.menu-icon > li > a:hover,
.full-dark .navbar-nav ul.user-menu.menu-icon > li > p:hover {
  border-color: var(--primary-color) !important;
}
.full-dark .static-data-box {
  box-shadow: 0px 10px 20px 0px #000000;
  background-color: #333333;
}
.select-error-set
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  /* line-height: 31px !important; */
}
body.full-dark .sidebar-nav .metismenu > li.active > a,
body.full-dark .sidebar-nav .metismenu > li.active > a:hover,
body.full-dark .sidebar-nav .metismenu a:hover,
body.full-dark .sidebar-nav .metismenu a:focus,
body.full-dark .sidebar-nav .metismenu a:active,
.full-dark.theme-cyan .sidebar-nav .metismenu > li.active > a,
.full-dark.theme-cyan .sidebar-nav .metismenu > li:hover > a {
  background-color: #141414 !important;
}
body.full-dark #main-content {
  background: #141414 !important;
}
body.full-dark #left-sidebar {
  background: #333333 !important;
}
body.full-dark .sidebar-nav .metismenu a,
.full-dark .advertiser-dashboard-data .date-range-section label,
.full-dark .chart-selection .align-items-start label {
  color: #fff !important;
}
.full-dark .mainICon {
  display: none;
}
.full-dark .hoverICon {
  display: block !important;
}

.full-dark
  .select2-container--default
  .select2-search--inline
  .select2-search__field::placeholder {
  color: #acacac !important;
}
.full-dark .navbar-nav ul.notifications li {
  background-color: #333;
  color: #fff !important;
}
.full-dark .navbar-nav ul.notifications li > a,
.full-dark .notifications .media .media-body,
.full-dark .navbar-nav ul.notifications li .timestamp,
.full-dark .social-media-link,
.full-dark .portal-version-data,
.full-dark .version-update {
  color: #fff !important;
}
.full-dark .portal-version-data svg {
  fill: #fff;
}
body.full-dark .navbar-fixed-top {
  background: #2e2e2e !important;
  border-color: #2e2e2e !important;
  box-shadow: 0px 10px 60px 0px #0000004d;
}
.full-dark .accordion-button::after {
  color: #fff;
}
.full-dark .accordian-table td svg path {
  fill: #fff;
}
.full-dark .accordian-table td span {
  color: #fff;
}
.full-dark .block-header h2::before {
  color: #fff;
}
.full-dark .cardData-section .card-body.active p,
.full-dark .cardData-section .card-body:hover p {
  color: #333 !important;
}
.full-dark .pricing-table .btn {
  background-color: #fff !important;
  color: var(--primary-color) !important;
}
.full-dark
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  border: 1px solid #aaa !important;
}
.full-dark .echarts-for-react svg text {
  fill: #fff !important;
}
.full-dark .textDesc {
  color: #acacac;
}
.full-dark .play-icon-svg path:last-child {
  stroke: #f0f0f0;
}
.full-dark .audio-controls-show .play-btn path,
.audio-controls-show .play-btn path {
  fill: none !important;
}
.advertiser-filter .select2-container--default .select2-selection--single {
  height: 40px !important;
}
.advertiser-filter
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 28px !important;
}
.full-dark #bacicTab2pan-3 h6 {
  color: #ffffff;
}
.full-dark .selectedFilter-droparea .themeInput {
  background-color: var(--bg-color-input) !important;
}
.secondary-importbtn {
  background-color: #5f6377 !important;
  border-color: #5f6377 !important;
  color: #fff;
}
.full-dark
  .filterSet-chart
  .select2-container--default
  .select2-selection--single {
  background-color: #e4f3ff !important;
}
.full-dark .gray-text {
  color: #acacac;
}
.full-dark .icon-trash {
  background-color: transparent;
}
.forMobile {
  display: none;
}
.full-dark .filterTable-data thead th {
  background-color: var(--primary-color) !important;
}
.full-dark .filterTable-data .child-td thead th {
  background-color: transparent !important;
}
.full-dark .pdfSet,
.full-dark .pdfSet #pdf-content {
  background-color: #333 !important;
}
.full-dark .pdfSet .cdr-report-data {
  box-shadow: none !important;
}
.full-dark .dark-blue-text {
  color: #fff !important;
}
.full-dark .advance-search-btn.btn-link {
  color: #fff;
}
.full-dark .accordion-item {
  background: #333 !important;
  border: #333;
  box-shadow: 0px 10px 60px 0px #000000 !important;
}
.full-dark .accordion-item:first-of-type > .accordion-header .accordion-button {
  color: #fff !important;
}

/* Dark theme css :: END */
.block-icon-action,
.table td svg.block-icon-action path {
  fill: #ff0000;
}
.dynamic-content-title {
  color: #5a5a5a !important;
}
.dynamic-data-set table thead th {
  border-top: 0;
}
.card.dynamic-data-set .header {
  display: none !important;
}
.card.dynamic-data-set .body {
  padding-top: 10px;
  padding-bottom: 0;
}
.otp-p {
  margin-bottom: 0.5rem !important;
}
.form-control:disabled {
  cursor: not-allowed;
}
.cdr-loader {
  display: flex;
  /* background-color: #dddd; */
  justify-content: center;
  text-align: center;
  margin: auto;
  align-items: center;
  width: 100%;
  position: absolute;
}
.graph-div {
  padding: 20px;
}
.graph-border {
  border-top: 1px solid #d9d9d9;
}
.plan-show-data:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--light-text-color);
}
.input-group .select2-container {
  width: 100% !important;
}
.currency-symbol-span {
  color: grey;
  position: absolute;
  left: 12px;
  cursor: none;
}
.currency-symbol-input {
  margin-top: 0.6rem;
  padding-right: 4px;
  color: #acacac !important;
}
.plan-input {
  position: relative !important;
  padding-left: 30px !important;
}
.import-rate .modal-body p {
  color: #acacac !important;
}
.route-modal .modal-body .weightage-p {
  color: #acacac !important;
  margin-bottom: 1rem !important;
}
.weightage-p {
  color: #6b6363 !important;
  margin-bottom: 1rem !important;
}
.line-height-li {
  line-height: 2.4;
}
.plan-fee-btn {
  border-color: #0596ff !important;
  color: #0596ff !important;
  border-width: initial !important;
  cursor: none !important;
}
.plan-fee-btn:hover {
  background-color: white !important;
}
.dial-code
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 42px !important;
}
.dial-code
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 16px !important;
}
.dial-code
  .select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  padding-top: 8px !important;
}
.view-details.modal .modal-header {
  border-bottom: 1px solid #c6c6c6 !important;
}
.tab-content .div-center,
.accordian-table .div-center {
  left: 120px !important;
}
/*Loader css*/
.main-loader {
  position: relative;
  width: 40px;
  height: 40px;
}

.loader-icon-container {
  position: relative;
  width: 50%;
  height: 50%;
  animation: zoomInOut 2s infinite ease-in-out;
}

.loader-icon {
  width: 100%;
  height: 100%;
}
.platform-chart-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 70vw;
  position: fixed;
  top: 0;
  left: 0;
}
.chart-div-center {
  text-align: center;
  justify-content: center;
  display: flex;
  height: 50vh;
  width: 50vw;
  position: fixed;
  top: 30vh;
  left: 0;
}
.div-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
.campaign-div-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.export-loader {
  display: flex;
  justify-content: center;
}

/* Create a spinning effect around the icon */
.main-loader::before {
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 45px;
  height: 45px;
  margin-top: -11px;
  margin-left: -12px;
  border-top: 2px solid #222829;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animation for zoom in and out */
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Animation for spinning effect */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-table-loader {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100%; /* Set height for the loader container */
  width: 100%; /* Full width of the parent container */
}
.main-table-loader::before {
  content: '';
  position: absolute;
  width: 45px;
  height: 45px;
  border-top: 2px solid #222829;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.full-dark .main-table-loader::before {
  border-top: 2px solid white;
}
.full-dark .main-loader::before {
  border-top: 2px solid white;
}
.table-loader-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-loader-icon {
  /* width: 100%; */
  height: 20px;
  animation: zoomInOut 2s infinite ease-in-out;
}
.skeleton-row {
  margin-bottom: 10px;
  margin-left: 10px !important;
}
.bulk-action-dropdown {
  width: 40% !important;
}
.publisher-portal-v {
  position: fixed !important;
  bottom: 0 !important;
}
/* Media query :: BEGIN */
@media screen and (min-width: 1440px) {
  .advertiser-dashboard-data .date-range-section label {
    width: 119px;
  }
  .static-data {
    gap: 30px;
  }
  .about-us {
    width: 60%;
  }
  .vertical-align-wrap.sign-up-section.login-page-data {
    height: 100%;
  }
}
@media screen and (min-width: 1640px) {
  .about-us {
    width: 65%;
  }
}
@media screen and (max-width: 1024px) {
  .cdr-report-data .export-pdf-btn {
    display: none;
  }
}
@media screen and (max-width: 1640px) {
  .toast-header .btn-close {
    margin-right: 10px !important;
  }
  .box-section .card {
    width: 98%;
  }
  .box-section .card ul li,
  .box-section .card .note {
    font-size: 16px;
  }
  .box-section .card h5 {
    font-size: 18px;
  }
  .box-section .card button {
    font-size: 17px;
  }
}
@media screen and (min-width: 768px) {
  .modal-dialog.modal-small {
    width: 400px;
  }
}
@media screen and (max-width: 1299px) and (min-width: 1201px) {
  .cardData-section .card-body {
    min-height: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .navbar-fixed-top .navbar-brand {
    flex: 0 0 auto;
    margin-left: 0;
  }
  .navbar-fixed-top .container-fluid {
    padding-left: 15px !important;
  }
  .about-us {
    width: 40%;
  }
}
@media screen and (max-width: 1025px) {
  .wallet-balnce h2 {
    font-size: 24px;
  }
  .full-dark .sidebar-scroll .user-account {
    padding-right: 7px;
    padding-left: 10px;
  }
  .full-dark .sidebar-scroll .user-account a {
    background-color: #141414 !important;
    box-shadow: 0px 10px 60px 0px #0000004d !important;
    border-radius: 10px;
    padding: 7px 10px;
  }
  .full-dark .sidebar-scroll .user-account a svg {
    margin-right: 7px !important;
  }
  .user-account .dropdown-toggle::after {
    display: block !important;
    margin-left: auto;
    margin-right: 10px;
    font-size: 22px;
  }
  .forDesktop {
    display: none;
  }
  .forMobile {
    display: block;
  }
  .advertiser-dashboard-data .chart-selection .align-items-start label {
    width: 105px;
  }
  .right-side-menu {
    display: none;
  }
  .navbar-fixed-top .navbar-brand {
    margin-left: auto;
    margin-right: 0;
  }
  #navbar-menu .notification-set {
    display: none;
  }
  #navbar-menu .user-account {
    width: 80px;
  }
  .menu-open-arrow i {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 5px 10px 5px;
    border-radius: 0 0 5px 5px;
    font-size: 22px;
  }
  .menu-open-arrow {
    position: fixed;
    right: 10px;
    top: 70px;
    z-index: 99;
    transition: all 0.1s ease-in-out;
  }
  .mobile-menu-section {
    transition: all 0.2s ease-in-out;
    display: none;
  }
  .navbar-right.mobile-menu-section.show {
    position: fixed;
    background: #fff;
    border-radius: 0px 0px 15px 15px;
    width: 100% !important;
    top: 73px;
    z-index: 4;
    left: 0;
    padding: 10px 30px;
    display: block;
    box-shadow: 0px 45px 60px #c7c7c761;
  }
  .full-dark .navbar-right.mobile-menu-section.show {
    background: #141414;
    box-shadow: 0px 15px 30px 8px #2929297d;
  }
  .full-dark .sidebar-scroll .user-account.d-lg-none a {
    background-color: #141414;
  }
  .full-dark .welcome-text {
    color: #fff;
  }
  .mobile-menu-section .right-side-nav.nav.navbar-nav li {
    flex: 0 0 45%;
    gap: 10px !important;
  }
  label,
  body,
  input,
  textarea,
  .form-label {
    font-size: 14px;
  }
  .menu-open-arrow.open-mobile-menu {
    top: 135px;
  }
  .country-selection-admin-data {
    gap: 7px;
  }
  .country-selection-admin-data .refresh-chart {
    font-size: 28px;
    width: 35px;
    height: 35px;
    line-height: 1.3;
    border-radius: 10px;
    margin-top: 3px;
  }
  .menu-open-arrow.open-mobile-menu.advertiser-mobile-menu {
    top: 195px;
  }
  .menu-open-arrow.open-mobile-menu i {
    transform: rotate(180deg);
    padding: 5px 10px 4px;
    border-radius: 5px 5px 0 0;
  }
  #navbar-menu {
    float: none !important;
  }
  .line-seprater,
  .user-notification {
    display: none !important;
  }
  .navbar-nav .dropdown-menu.user-menu {
    right: 10px;
    top: 90px !important;
  }
  .advertiser-sidebar .navbar-nav .dropdown-menu.user-menu {
    right: 10px;
    top: 84px !important;
  }
}

@media screen and (max-width: 992px) {
  .wallet-detail-show-section {
    padding-left: 15px !important;
  }
  .advertiser-sidebar .navbar-nav .dropdown-menu.user-menu {
    right: -3px;
    top: 74px !important;
  }
  .set-m-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .navbar-fixed-top .navbar-right {
    width: calc(100% - 400px) !important;
  }
  .static-data-box {
    display: block;
  }
  .about-us {
    padding-left: 0;
  }
  .dropdown-menu-end {
    right: -2px !important;
  }
  .vertical-align-middle.signup-data-form {
    display: block;
    position: relative;
    padding-bottom: 50px;
  }
  .about-us {
    width: 90%;
    margin: auto;
    padding: 50px 0;
  }
  .sign-up-section .about-us {
    margin-top: 0;
  }
  .auth-main::before {
    width: 100% !important;
  }
  .auth-main::before {
    width: 100% !important;
  }
  .auth-box.login-box {
    margin-top: 0px;
  }
  .vertical-align-wrap.sign-up-section {
    display: block !important;
  }
}
@media screen and (max-width: 767.25px) {
  .d-none {
    display: none;
  }
  .sticky-filter-icon-container .sticky-filter-icon {
    position: sticky;
    width: max-content;
    left: 1.23rem;
  }
  .auth-box .helper-text a {
    font-size: 12px;
  }
  .country-selection-admin-data .refresh-chart {
    margin-top: 0;
    position: absolute;
    right: 15px;
    top: -45px;
  }
  .country-selection-admin-data {
    position: relative;
  }
  .country-selection-admin-data .chart-selection.set-lh {
    width: 100%;
  }
  .sidebar-nav .metismenu a {
    font-size: 14px;
  }
  .user-hprofile {
    display: none !important;
  }
  .pricing-table ul.w-150px {
    flex: 0 0 290px;
  }
  .theme-cyan .btn {
    font-size: 14px;
  }
  .user-profile {
    flex: 0 0 100px;
  }
  .live-calls-show,
  .add_icon {
    margin-right: 0px;
  }
  .box-section {
    border-radius: 0;
    padding: 20px !important;
  }
  .add_icon {
    margin-right: 0;
  }
  .navbar-nav .dropdown-menu {
    overflow: visible !important;
  }
  .user-menu::before {
    right: 95px;
  }
  .charts-section-bg {
    flex-direction: column-reverse;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    background-size: 20px 20px;
    top: 45px;
  }
  .waltter-use-data {
    flex: 0 0 50%;
  }
  .advertiser-dashboard-data .set-width {
    width: 100%;
    margin-bottom: 15px;
    display: block !important;
  }
  .advertiser-dashboard-data .set-width:first-child {
    padding: 0 15px;
  }
  .chart-selection.chart-data-drops.d-md-flex .set-width:first-child {
    padding: 0;
  }
  .create-target label {
    font-size: 16px;
  }
  .chart-data-drops .select2-container {
    margin-left: 0 !important;
  }
  .advertiser-dashboard-data .date-range-section label {
    width: 100%;
  }
  .advertiser-dashboard-data .chart-selection .align-items-start label {
    width: 100%;
  }
  .advertiser-dashboard-data #reportrange {
    width: 100%;
  }
  .chart-selection,
  .chart-selection .d-flex,
  .chart-selection.set-lh {
    display: block !important;
  }
  .chart-selection .align-items-start label {
    width: 100%;
  }
  .chart-selection.set-lh label {
    display: block !important;
  }
  .card .header.d-flex .table-right-side-menu {
    margin-left: 0;
  }
  .sip-selection.d-flex {
    display: block !important;
  }
  .pagination.justify-content-end {
    display: block !important;
    padding: 0 20px;
  }
  .auth-platform .card,
  .auth-box .card {
    background-color: #fff !important;
  }
  .pagination span.length-data {
    margin-bottom: 10px;
    justify-content: start;
  }
  .country-display.gap-3 {
    gap: 5px !important;
  }
  .accordian-table td span {
    padding: 0 2px !important;
  }
  #left-sidebar {
    z-index: 1000 !important;
  }
  .sip-selection label {
    display: block;
  }
  #reportrange {
    font-size: 14px;
    width: 250px;
  }
  .date-range-section #reportrange {
    width: 100%;
  }
  .date-range-section label {
    width: 100%;
  }
  .modal-body p.terms-content {
    height: 300px;
  }
  .wallet-balnce h2 {
    font-size: 20px;
  }
  .navbar-fixed-top .navbar-btn button {
    font-size: 26px !important;
    padding-left: 0px;
    padding-right: 0;
  }
  .wallet-detail-show-section {
    grid-template-columns: auto;
    margin-bottom: 10px;
  }
  .table-right-side-menu {
    flex-wrap: wrap;
  }
  .cardData-section .card {
    margin-bottom: 15px;
  }
  .container-fluid .cardData-section .card:first-child {
    border-radius: 15px !important;
  }
  .modal.fade.show {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .w-150px {
    width: 150px;
  }
  .w-100 {
    width: 100% !important;
  }
  .did-table td {
    float: right !important;
  }
  .w-100px {
    width: 150px;
  }
  .card .header .setMmargin {
    margin-bottom: 10px !important;
  }
  .calls-information h6 {
    font-size: 14px;
  }
  .calls-information img {
    width: 20px;
    height: 20px;
  }
  .calls-information .d-flex {
    gap: 5px !important;
  }
  .calls-information {
    padding: 0 25px !important;
  }
  .refresh-chart i {
    width: 35px;
    height: 35px;
    font-size: 25px;
    line-height: 1.4;
  }
  .dashboard-data .refresh-chart i {
    width: 25px;
    height: 25px;
    font-size: 30px;
    line-height: 1;
    background-color: transparent !important;
    border: 0 !important;
  }
  .cardData-section .card-body {
    font-size: 12px;
  }
  .numShow {
    font-size: 22px;
  }
  .amountData {
    font-size: 18px;
  }
  .themeTabs .nav-tabs-new > li > a,
  .themeTabs .nav-tabs-new > li > .nav-link {
    padding: 7px 5px;
    font-size: 15px;
  }
  .w-250px {
    width: 200px !important;
  }
  .cardSet .divCenter {
    width: 100%;
  }
  .navbar-nav ul.notifications {
    width: 90% !important;
  }
  .user-account .dropdown-toggle .user-name {
    padding-right: 5px;
    max-width: 110px;
  }
  .user-account .user-photo {
    width: 25px !important;
    height: 25px;
    margin-right: 2px !important;
  }
  #navbar-menu .notification-set {
    margin-top: 0px !important;
  }
  .navbar > .container-fluid {
    justify-content: flex-start;
    gap: 5px;
  }
  .navbar-fixed-top .navbar-right {
    width: 100% !important;
  }
  .navbar-fixed-top .navbar-brand img {
    width: 140px !important;
  }
  .theme-daterange {
    position: relative;
    right: auto;
    top: 10px;
  }
  .with-dateIn.table-right-side-menu {
    height: 90px;
    align-items: start !important;
    margin-top: 10px;
  }
  .set-width {
    width: 100%;
    display: block;
  }
  .did-selection-camp label {
    font-size: 16px;
  }
  .themeTabs .nav-tabs-new.switch-tab-ui > li > a,
  .themeTabs .nav-tabs-new.switch-tab-ui > li > a:hover {
    font-size: 16px;
    padding: 5px 10px;
  }
  .dashboard-data .refresh-chart {
    margin-top: -50px;
    width: 30px;
    height: 30px;
    border-radius: 9px;
    font-size: 20px;
    margin-right: 25px;
  }
  .box-transfer .action-field i {
    margin: auto auto 2px;
  }
  .block-header h2 {
    border-bottom: 1px solid var(--breadcrum-border-color);
    padding: 10px 0px;
    margin: 0 15px;
    font-size: 15px !important;
  }
  .block-header h2::before {
    top: 22px;
    left: 22px;
    font-size: 15px;
  }
  .lnr-menu {
    font-size: 22px;
  }
  form label,
  .fomr-label {
    font-size: 14px;
  }
  .icon-trash {
    background-color: transparent !important;
    color: #acacac !important;
  }
  .themeTabs .tab-pane {
    padding: 10px 10px !important;
  }
  .card-tab-header ul {
    border-bottom: 0;
  }
  .table-right-side-menu .refresh-chart {
    margin-right: 0 !important;
    width: 40px;
    height: 40px;
    line-height: 1.5;
    font-size: 26px;
  }
  .table-right-side-menu .refresh-chart i {
    width: auto;
    height: auto;
    font-size: inherit;
    line-height: inherit;
  }
  #main-content {
    padding: 10px 0 !important;
  }
  #main-content .container-fluid:first-child {
    padding: 0;
  }
  #main-content .container-fluid .row.clearfix {
    margin: 0;
  }
  #main-content .container-fluid:first-child .col-lg-12:first-child {
    padding: 0 !important;
  }
  .container-fluid .card:first-child {
    border-radius: 0 !important;
  }
  .card .header {
    padding: 20px 20px 0 !important;
    display: block !important;
  }
  .notes-text-client {
    padding: 0 10px;
  }
  .table-responsive {
    padding: 10px !important;
  }
  .table thead th {
    border-top: 0;
  }
  .calls-information,
  .country-selection-admin-data,
  .chart-date-section,
  .chart-data-drops {
    padding: 0 15px;
  }
  .chart-selection.chart-data-drops.align-items-start.set-width {
    padding: 0;
  }
  .block-header .row {
    margin: 0;
  }
  .notification-table table tbody td:first-child {
    min-width: 300px;
  }
  .container-fluid.box-section .card {
    border-radius: 10px !important;
  }
  .accordian-table .accordian-header-set {
    gap: 3px !important;
  }
  .accordian-table .accordian-header-set .kyc-actions {
    gap: 5px !important;
  }
  .accordian-table .accordian-header-set .accordion-header button {
    padding-right: 0px !important;
  }
  .upload-preview-section label {
    max-width: 100%;
  }
  .upload-preview-section {
    flex-wrap: wrap;
  }
  .kyc-document-show-modal img {
    max-width: 100%;
  }
  .themeTabs .tab-pane#bacicTab22pan-add {
    padding: 10px 0 !important;
  }
  #bacicTab2pan-3 .row.p-4 {
    padding: 0px !important;
  }
  .static-data img {
    width: 40px;
    height: 40px;
  }
  .static-num {
    font-size: 18px;
  }
  .static-data .light-text {
    font-size: 14px;
  }
  .sidebar-nav .metismenu {
    height: calc(100vh - 200px);
  }
  /* add publisher media query */
  .cdr-dropdown-container {
    display: grid !important;
    grid-template-columns: repeat(1, auto);
  }
}
@media screen and (max-width: 376px) {
  .themeTabs .nav-item.mr-1 {
    margin-right: 0 !important;
  }
  .react-dropdown-select.form-control {
    height: 60px !important;
    overflow: auto;
  }
  .kyc-reject-comment {
    width: 250px;
    top: 80px;
  }
  .navbar-fixed-top .navbar-brand img {
    width: 100px !important;
    margin-left: 5px;
  }
  .navbar-fixed-top .navbar-btn {
    flex: 0 0 12px;
  }
  .navbar-brand {
    padding: 0px 5px !important;
  }
  .chart-name {
    left: -28px;
  }
}
@media screen and (max-width: 425px) {
  .box-transfer .d-flex {
    width: 100%;
    justify-content: space-between;
  }
  .box-setup {
    width: 100%;
  }
  .box-transfer .action-field {
    width: 30%;
  }
  .box-transfer .avaialble-box,
  .box-transfer .assigned-box {
    width: 100%;
  }
  .themeInput.dropdown-toggle.btn.green-btn {
    width: 40px !important;
    height: 40px;
  }
  .themeInput.dropdown-toggle.btn.green-btn img {
    width: 15px;
    height: 15px;
  }
  .credit-set .table-responsive table tr th:nth-child(2),
  .credit-set .table-responsive table tr td:nth-child(2) {
    min-width: 150px;
  }
  .credit-set
    .table-responsive
    table
    input[type='date']::-webkit-calendar-picker-indicator {
    top: 10px;
  }
  .accordian-table span.primary-text {
    font-size: 12px;
  }
  .date-calender-set input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }
  .date-calender-set input[type='date'] {
    position: relative;
    padding-right: 10px;
    -webkit-appearance: none !important;
    appearance: none !important;
  }
  .date-calender-set input[type='date']::after {
    content: '';
    background-image: url('assets/images/calender-icon.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .date-calender-set input[type='date']:empty::before {
    content: attr(placeholder);
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .date-calender-set input[type='date']:focus::before,
  .datewithVal.date-calender-set input[type='date']::before {
    content: '';
  }
  .credit-set .w-200px {
    min-width: 200px !important;
  }
  .charts-tab .tab-content {
    margin-top: 20px;
  }
  .set-text-m {
    text-align: left;
  }
  .accordian-table .set-border {
    display: none;
  }
  .about-work-note {
    padding: 0 10px;
  }
  .about-work-note p {
    font-size: 12px;
  }
  .width-set {
    width: 100%;
  }
}
.margin-top-2px {
  margin-top: 2px !important;
}
.image-name-label {
  padding: 0.6rem 0.75rem !important;
}
.dynamic-page-image-note {
  margin-bottom: 0.7rem !important;
}
@media screen and (max-width: 326.25px) {
  .user-account .user-photo {
    width: 22px !important;
    height: 22px;
  }
  .navbar-fixed-top .navbar-brand {
    padding-left: 0 !important;
  }
}
/* Media query :: END */
